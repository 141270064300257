<template>
    <div class="flowchart-container">
      <!-- Hero Section -->
      <div class="hero-background"></div>
      <div class="hero-image">
        <img src="@/assets/Resume_Template_Hero.png" alt="Career Roadmap Hero" />
      </div>
      <div class="hero-text">
        <h1>AI Career Roadmap</h1>
        <p>
          Our goal is to help you advance your career by building new skills. This
          personalized roadmap provides structured guidance for career growth,
          covering essential knowledge, skills development, and learning resources
          tailored to your goals.
        </p>
      </div>
  
      <!-- Related Roadmaps -->
      <div class="related-roadmaps">
        <h3>Related Roadmaps</h3>
        <ul>
          <li v-for="(roadmap, index) in relatedRoadmaps" :key="index">
            <a href="#">{{ roadmap }}</a>
          </li>
        </ul>
      </div>
  
      <!-- JointJS Flowchart -->
      <div id="paper" class="flowchart-paper"></div>
  
      <!-- Sidebar for displaying node details -->
      <div v-if="selectedNode" class="sidebar">
        <h3>{{ selectedNode.attributes.attrs.label.text }}</h3>
        <p>{{ selectedNode.attributes.description }}</p>
        <div v-if="selectedNode.attributes.resources">
          <h4>Learn more:</h4>
          <div class="resources">
            <h5>Free Resources:</h5>
            <ul>
              <li
                v-for="(resource, index) in selectedNode.attributes.resources.free"
                :key="index"
              >
                <a :href="resource.link" target="_blank">{{
                  resource.type
                }}: {{ resource.title }}</a>
              </li>
            </ul>
            <h5>Premium Resources:</h5>
            <ul>
              <li
                v-for="(resource, index) in selectedNode.attributes.resources.premium"
                :key="index"
              >
                <a :href="resource.link" target="_blank">{{
                  resource.type
                }}: {{ resource.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
      <!-- Form to take input for career roadmap generation -->
      <div class="input-form">
        <h2>Generate Your Career Roadmap</h2>
        <div class="form-group">
          <label for="career">Career:</label>
          <input v-model="career" placeholder="e.g., Data Scientist, UX Designer" />
        </div>
  
        <div class="form-group">
          <label for="skills">Current Skills:</label>
          <input v-model="skills" placeholder="List your current skills, e.g., Python, Figma" />
        </div>
  
        <div class="form-group">
          <label for="experience">Experience Level:</label>
          <select v-model="experience">
            <option disabled value="">Select your experience level</option>
            <option>Beginner</option>
            <option>Intermediate</option>
            <option>Advanced</option>
          </select>
        </div>
  
        <div class="form-group">
          <label for="goals">Specific Goals:</label>
          <textarea
            v-model="goals"
            placeholder="Describe any specific goals or focus areas you have"
            class="non-adjustable"
          ></textarea>
        </div>
  
        <button @click="fetchRoadmap">Generate Roadmap</button>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, reactive, onMounted } from "vue";
  import * as joint from "jointjs";
  
  export default defineComponent({
    name: "CareerRoadmap",
    setup() {
      const selectedNode = ref(null);
      const graph = ref(null);
      const paper = ref(null);
  
      const career = ref("");
      const skills = ref("");
      const experience = ref("");
      const goals = ref("");
  
      const relatedRoadmaps = [
        "AI and Data Scientist Roadmap",
        "SQL Roadmap",
        "Python Roadmap",
        "Prompt Engineering Roadmap",
      ];
  
      const sampleRoadmapData = reactive([
        {
          title: "Data Analyst",
          topics: [
            {
              title: "Building a Strong Foundation",
              subtopics: [
                {
                  title: "Analysis / Reporting with Excel",
                  skills: [
                    {
                      name: "IF",
                      description: "The IF function allows you to make logical comparisons between values.",
                    },
                    {
                      name: "VLOOKUP / HLOOKUP",
                      description: "VLOOKUP is used to look up a value in a table and return a corresponding value.",
                    },
                  ],
                },
                {
                  title: "Learn SQL",
                  description: "Learn SQL for querying databases and managing data.",
                  skills: [
                    {
                      name: "Basic SQL Queries",
                      description: "Learn how to write SELECT, INSERT, UPDATE, and DELETE queries.",
                    },
                    {
                      name: "Joins",
                      description: "Understand how to join tables using INNER JOIN, LEFT JOIN, and RIGHT JOIN.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]);
  
      const createRoadmapGraph = (roadmapData) => {
        graph.value = new joint.dia.Graph();
        paper.value = new joint.dia.Paper({
          el: document.getElementById("paper"),
          model: graph.value,
          width: '100%',
          height: '100%',
          gridSize: 10,
          drawGrid: false,
          interactive: {
            elementMove: false,
            addLinkFromMagnet: false
          },
          defaultLink: new joint.dia.Link({
            attrs: {
              '.marker-target': { d: 'M 10 0 L 0 5 L 10 10 z' }
            }
          }),
          validateConnection: function() {
            return false;
          }
        });
  
        const elementWidth = 180;
        const elementHeight = 60;
        const horizontalSpacing = 250;
        const verticalSpacing = 120;
  
        let maxY = 0;
  
        const createRectWithToggle = (text, x, y, width, height, fill) => {
          const rect = new joint.shapes.standard.Rectangle({
            position: { x, y },
            size: { width, height },
            attrs: {
              body: { fill, stroke: '#000', strokeWidth: 2, rx: 10, ry: 10 },
              label: { 
                text, 
                fill: 'white', 
                fontSize: 12, 
                fontWeight: 'bold',
                refX: '50%',
                refY: '50%',
                yAlignment: 'middle',
                xAlignment: 'middle'
              },
              collapse: {
                refX: '100%',
                refY: '50%',
                refX2: -10, // Position on the right
                text: '−',  // Collapse button
                fill: 'white',
                fontSize: 16,
                fontWeight: 'bold',
                cursor: 'pointer',
                event: 'element:collapse:pointerdown',
                title: 'Collapse'
              },
              expand: {
                refX: '100%',
                refY: '50%',
                refX2: -10,  // Position on the right
                text: '+',  // Expand button
                fill: 'white',
                fontSize: 16,
                fontWeight: 'bold',
                cursor: 'pointer',
                event: 'element:expand:pointerdown',
                display: 'none',  // Hidden initially
                title: 'Expand'
              }
            }
          });
  
          return rect;
        };
  
        const toggleVisibility = (rect, action) => {
          const isCollapsed = action === 'collapse';
          
          // Toggle button visibility between collapse and expand
          rect.attr('collapse/display', isCollapsed ? 'none' : 'inline');
          rect.attr('expand/display', isCollapsed ? 'inline' : 'none');
          
          // Hide/show connected nodes and links
          const links = graph.value.getConnectedLinks(rect, { outbound: true });
          links.forEach(link => {
            const targetId = link.get('target').id;
            const targetElement = graph.value.getCell(targetId);
            if (targetElement) {
              targetElement.set('hidden', isCollapsed);
              link.set('hidden', isCollapsed);
              if (isCollapsed) {
                hideDescendants(targetElement);
              } else {
                showDescendants(targetElement);
              }
            }
          });
  
          // Refit the paper to the new dimensions
          paper.value.fitToContent({ padding: 20, allowNewOrigin: 'any' });
        };
  
        const hideDescendants = (element) => {
          const links = graph.value.getConnectedLinks(element, { outbound: true });
          links.forEach(link => {
            link.set('hidden', true);
            const targetId = link.get('target').id;
            const targetElement = graph.value.getCell(targetId);
            if (targetElement) {
              targetElement.set('hidden', true);
              hideDescendants(targetElement);
            }
          });
        };
  
        const showDescendants = (element) => {
          if (!element.attr('expand/display') || element.attr('expand/display') === 'none') {
            const links = graph.value.getConnectedLinks(element, { outbound: true });
            links.forEach(link => {
              link.set('hidden', false);
              const targetId = link.get('target').id;
              const targetElement = graph.value.getCell(targetId);
              if (targetElement) {
                targetElement.set('hidden', false);
                showDescendants(targetElement);
              }
            });
          }
        };
  
        const createLink = (source, target, isDashed = false) => {
          return new joint.shapes.standard.Link({
            source: { id: source.id },
            target: { id: target.id },
            router: { name: 'orthogonal' },
            connector: { name: 'rounded' },
            attrs: {
              line: {
                stroke: '#333',
                strokeWidth: 2,
                strokeDasharray: isDashed ? '5, 5' : '',
                targetMarker: {
                  type: 'path',
                  d: 'M 10 -5 0 0 10 5 Z'
                }
              }
            }
          });
        };
  
        const addBranch = (branch, x, y) => {
          const branchRect = createRectWithToggle(branch.title, x, y, elementWidth, elementHeight, '#f4b400');
          branchRect.addTo(graph.value);
          
          let maxBranchY = y;
          let topicX = x + horizontalSpacing;
          
          branch.topics.forEach((topic, topicIndex) => {
            const [topicRect, topicMaxY] = addTopic(topic, topicX, y + topicIndex * (verticalSpacing * 2));
            createLink(branchRect, topicRect).addTo(graph.value);
            maxBranchY = Math.max(maxBranchY, topicMaxY);
          });
          
          return maxBranchY;
        };
  
        const addTopic = (topic, x, y) => {
          const topicRect = createRectWithToggle(topic.title, x, y, elementWidth, elementHeight, '#1a73e8');
          topicRect.addTo(graph.value);
          
          let maxTopicY = y;
          let subtopicX = x + horizontalSpacing;
          
          topic.subtopics.forEach((subtopic, subtopicIndex) => {
            const [subtopicRect, subtopicMaxY] = addSubtopic(subtopic, subtopicX, y + subtopicIndex * verticalSpacing);
            createLink(topicRect, subtopicRect, true).addTo(graph.value);
            maxTopicY = Math.max(maxTopicY, subtopicMaxY);
          });
          
          return [topicRect, maxTopicY];
        };
  
        const addSubtopic = (subtopic, x, y) => {
          const subtopicRect = createRectWithToggle(subtopic.title, x, y, elementWidth, elementHeight, '#fbbc05');
          subtopicRect.addTo(graph.value);
          
          let maxSubtopicY = y;
          let skillX = x + horizontalSpacing;
          
          if (subtopic.skills) {
            subtopic.skills.forEach((skill, skillIndex) => {
              const skillRect = createRectWithToggle(skill.name, skillX, y + skillIndex * (verticalSpacing / 2), elementWidth, elementHeight, '#34a853');
              skillRect.addTo(graph.value);
              createLink(subtopicRect, skillRect, true).addTo(graph.value);
              maxSubtopicY = Math.max(maxSubtopicY, y + skillIndex * (verticalSpacing / 2));
            });
          }
          
          return [subtopicRect, maxSubtopicY];
        };
  
        let currentY = 50;
        roadmapData.forEach((branch) => {
          const branchMaxY = addBranch(branch, 50, currentY);
          currentY = branchMaxY + verticalSpacing * 2;
          maxY = Math.max(maxY, branchMaxY);
        });
  
        paper.value.setDimensions('100%', maxY + elementHeight * 2);
  
        paper.value.on('element:collapse:pointerdown', function(elementView) {
          toggleVisibility(elementView.model, 'collapse');
        });
  
        paper.value.on('element:expand:pointerdown', function(elementView) {
          toggleVisibility(elementView.model, 'expand');
        });
  
        paper.value.on('element:pointerclick', (elementView) => {
          selectedNode.value = elementView.model;
        });
  
        paper.value.scale(0.8);
        centerContent();
      };
  
      const centerContent = () => {
        const contentBBox = graph.value.getBBox();
        const currentScale = paper.value.scale();
        const paperWidth = paper.value.getComputedSize().width;
        const paperHeight = paper.value.getComputedSize().height;
        
        const tx = (paperWidth - contentBBox.width * currentScale.sx) / 2 - contentBBox.x * currentScale.sx;
        const ty = (paperHeight - contentBBox.height * currentScale.sy) / 2 - contentBBox.y * currentScale.sy;
        
        paper.value.translate(tx, ty);
      };
  
      onMounted(() => {
        createRoadmapGraph(sampleRoadmapData);
      });
  
      const fetchRoadmap = async () => {
        const apiUrl = "https://your-api-url.com/generate-roadmap";
        const requestData = {
          career: career.value,
          skills: skills.value,
          experience_level: experience.value,
          specific_goals: goals.value,
        };
  
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestData),
          });
          const data = await response.json();
  
          if (data.branches) {
            createRoadmapGraph(data.branches);
          } else {
            console.error("No branches found in the response.");
          }
        } catch (error) {
          console.error("Error fetching roadmap data:", error);
        }
      };
  
      return {
        career,
        skills,
        experience,
        goals,
        fetchRoadmap,
        relatedRoadmaps,
        selectedNode,
      };
    },
  });
  </script>
  
  <style scoped>
  .flowchart-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f3ede2;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .hero-background {
    background-color: #f3ede2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: -1;
  }
  
  .hero-image {
    width: 20vw;
    max-width: 200px;
    margin: 0 auto 20px auto;
    position: relative;
    z-index: 1;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
  }
  
  .hero-text {
    text-align: center;
    margin-bottom: 30px;
    font-family: "FixelDisplay", sans-serif;
  }
  
  .related-roadmaps {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .related-roadmaps ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .related-roadmaps li {
    margin-bottom: 5px;
  }
  
  .flowchart-paper {
    flex-grow: 1;
    border: 1px solid #ddd;
    background-color: #fff;
    overflow: hidden;
    min-height: 600px;
    margin: 20px 0;
  }
  
  .sidebar {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 300px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .input-form {
    width: 70%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .form-group label {
    align-self: flex-start;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  button {
    background-color: #e07b3c;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    display: block;
    margin: 20px auto 0;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #d5692e;
  }
  </style>
  