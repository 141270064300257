// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/Home.vue';
import AboutPage from '@/views/About.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'; 
import ContactUs from '@/views/ContactUs.vue';
import InterviewHelp from '@/views/InterviewHelp.vue';
import ResumeBestPractices from '@/views/ResumeBestPractices.vue';
import ResumeTemplate from '@/views/ResumeTemplate.vue';
import GenerateCustomizedResume from '@/views/GenerateCustomizedResume.vue';
import InterviewStudyGuide from '@/views/InterviewStudyGuide.vue';
import MockInterview from '@/views/MockInterview.vue';
import CareerCoach from '@/views/CareerCoach.vue';
import CareerRoadmap from '@/views/CareerRoadmap.vue';
import JobSearch from '@/views/JobSearch.vue';
import JobDetails from '@/components/JobDetails.vue';
import ApplyJob from '@/components/ApplyJob.vue';

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/about', name: 'About', component: AboutPage },
  { path: '/contact', name: 'ContactUs', component: ContactUs },
  { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/interview-help', name: 'InterviewHelp', component: InterviewHelp, meta: { transition: 'fade' } },
  { path: '/resume-best-practices', name: 'ResumeBestPractices', component: ResumeBestPractices, meta: { transition: 'fade' } },
  { path: '/resume-template', name: 'ResumeTemplate', component: ResumeTemplate, meta: { transition: 'fade' } },
  { path: '/generate-customized-resume', name: 'GenerateCustomizedResume', component: GenerateCustomizedResume, meta: { transition: 'fade' } },
  { path: '/interview-study-guide', name: 'InterviewStudyGuide', component: InterviewStudyGuide, meta: { transition: 'fade' } },
  { path: '/mock-interview', name: 'MockInterview', component: MockInterview, meta: { transition: 'fade' } },
  { path: '/career-coach', name: 'CareerCoach', component: CareerCoach, meta: { transition: 'fade' } },
  { path: '/career-roadmap', name: 'CareerRoadmap', component: CareerRoadmap, meta: { transition: 'fade' } },
  {
    path: '/job-search', // New route for development
    name: 'JobSearch',
    component: JobSearch,
  },
  { path: '/jobs/:docId', name: 'JobDetails', component: JobDetails },
  {
    path: '/apply/:docId',
    name: 'JobApply',
    component: ApplyJob,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log('Triggered scroll behavior:', { to, from, savedPosition });
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'auto' }; // Ensure a smooth scroll to the top
    }
  },
});

export default router;
