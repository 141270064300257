<template>
  <form @submit.prevent="submitForm">
    <label for="email">Email Address</label>
    <input
      type="email"
      v-model="email"
      id="email"
      name="email"
      placeholder="Enter your email"
      autocomplete="email"
      required
    />
    <p v-if="emailError" class="error-message">Please enter a valid email address.</p>
    <button type="submit" class="sign-up-form-btn">Join Waitlist</button>
  </form>
</template>

<script>
import { db } from '@/firebase'; // Import Firestore instance
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'; // Import Firestore functions

export default {
  name: 'SignUpForm',
  data() {
    return {
      email: '',
      emailError: false,
    };
  },
  methods: {
    async submitForm() {
      if (!this.isValidEmail(this.email)) {
        this.emailError = true;
        return;
      }
      try {
        // Use Firestore's modular functions to add to the 'waitlist' collection
        await addDoc(collection(db, 'waitlist'), {
          email: this.email,
          timestamp: serverTimestamp(), // Use Firebase's serverTimestamp function
        });
        alert('Thank you for signing up!');
        this.email = ''; // Clear the input field
        this.emailError = false;
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('An error occurred. Please try again later.');
      }
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
  },
};
</script>

<style scoped>
/* General Form Styles */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

label {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

input[type='email'] {
  padding: 12px 20px;
  border-radius: 25px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input[type='email']:focus {
  border-color: #fc7115;
  outline: none;
  box-shadow: 0 0 8px rgba(252, 113, 21, 0.5);
}

button {
  margin-top: 20px;
}

/* Button Styling */
.sign-up-form-btn {
  background-color: #fc7115;
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: pulse 2s infinite;
  
}

.sign-up-form-btn:hover {
  background-color: #e35d0a;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(252, 113, 21, 0.7);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(252, 113, 21, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(252, 113, 21, 0);
  }
}

/* Error Message Styling */
.error-message {
  color: red;
  margin-bottom: 10px;
}
</style>
