<template>
  <div class="interview-guide">
    <!-- Background container for full width -->
    <div class="hero-background"></div>

    <!-- Hero Image -->
    <div class="hero-image">
      <img src="@/assets/Interview_Study_Guide_Hero.png" alt="Interview Study Guide Hero">
    </div>

    <!-- Centered Header Section -->
    <div class="hero-text">
      <h1>Generate Interview Study Guide</h1>
      <p>Paste the job description and your resume to get a personalized interview study guide.</p>
    </div>

    <!-- Left-aligned Form Section -->
    <div class="form-group">
      <h2>Paste Job Description</h2>
      <textarea 
        v-model="jobDescription" 
        placeholder="Enter the job description here" 
        rows="5" 
        @input="checkJobDescriptionWordCount">
      </textarea>
      <!-- Word count display and warning -->
      <p :class="{'word-limit-warning': jobDescriptionWordCount > 600, 'word-count': jobDescriptionWordCount <= 600}">
        {{ jobDescriptionWordCount }}/600
      </p>
      <p v-if="jobDescriptionWordCount > 600" class="word-limit-warning">
        The job description exceeds 600 words. Please shorten it.
      </p>

      <h2>Paste Resume Text</h2>
      <textarea 
        v-model="resumeText" 
        placeholder="Enter your resume here" 
        rows="5" 
        @input="checkResumeWordCount">
      </textarea>
      <!-- Resume Word Count and Note -->
      <p :class="{'word-limit-warning': resumeWordCount > 600, 'word-count': resumeWordCount <= 600}">
        {{ resumeWordCount }}/600
      </p>
      <p v-if="resumeWordCount > 600" class="word-limit-warning">
        The resume exceeds 600 words. Please shorten it.
      </p>

      <div class="button-container">
        <button 
          @click="generateStudyGuide" 
          :disabled="isLoading || jobDescription.trim().length === 0 || jobDescriptionWordCount > 600 || resumeWordCount > 600"
        >
          <span v-if="!isLoading">Generate Study Guide</span>
          <span v-else>Generating...</span>
        </button>
      </div>

    </div>

    <!-- Study Guide Preview -->
    <div v-if="pdfUrl" class="resume-preview">
      <h2>Your Study Guide Preview</h2>
      <iframe :src="pdfUrl" frameborder="0" class="resume-preview-frame"></iframe>
      <a :href="pdfUrl" download="study_guide.pdf" class="cta-btn">Download PDF</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterviewStudyGuide',
  data() {
    return {
      jobDescription: '', // User-provided job description
      resumeText: '',     // User-provided resume text
      isLoading: false,   // Loading state for button
      pdfUrl: '',         // URL to the generated PDF
      jobDescriptionWordCount: 0, // Word count for job description
      resumeWordCount: 0,         // Word count for resume
    };
  },
  methods: {
    checkJobDescriptionWordCount() {
      this.jobDescriptionWordCount = this.jobDescription.split(/\s+/).filter(word => word.length > 0).length;
    },
    checkResumeWordCount() {
      this.resumeWordCount = this.resumeText.split(/\s+/).filter(word => word.length > 0).length;
    },
    async generateStudyGuide() {
      if (this.jobDescriptionWordCount > 600 || this.resumeWordCount > 600) {
        alert('The job description or resume exceeds the 600-word limit. Please shorten it.');
        return;
      }
      this.isLoading = true;

      try {
        const formData = new FormData();
        formData.append('jobDescription', this.jobDescription);
        formData.append('resumeText', this.resumeText);

        const response = await fetch('/interview_study_guide/', {
          method: 'POST',
          body: formData,
        });

        const blob = await response.blob();
        this.pdfUrl = URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error generating study guide:', error);
        alert('An error occurred while generating the study guide.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Background container */


.hero-background {
  background-color: #F3EDE2; /* Same background color */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Full viewport width */
  height: 100%;
  z-index: -1; /* Place behind the content */
}

/* Centered Hero Text Styling */
.hero-text {
  text-align: center;
  margin-bottom: 30px;
  font-family: 'FixelDisplay', sans-serif;
}

.hero-image {
  width: 20vw;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.hero-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.interview-guide {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'FixelDisplay', sans-serif;
}

.form-group {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fbf7f0;
  
  width: 60vw;       /* Use a flexible width instead of min-width */
  max-width: 800px;  /* Set a max-width to prevent it from stretching too much */
  
  margin-left: auto;  /* Use auto margins to center the form */
  margin-right: auto;
  font-family: 'FixelDisplay', sans-serif;
}

textarea {
  display:block;
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
  resize: none; /* Enables resizing on both axes */
  min-height: 100px; /* Set a minimum height for resizing */
  margin: 0 auto;
  font-family: 'FixelDisplay', sans-serif;
}

button {
  background-color: #E07B3C;
  color: #fff;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
  font-family: 'FixelDisplay', sans-serif;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adds some space above the button */
}
button:hover {
  background-color: #D2691E;
}

button:disabled {
  background-color: #999;
}

.resume-preview {
  margin-top: 40px;
  text-align: center;
}

.resume-preview-frame {
  width: 100%;
  height: 600px;
  border: none;
  margin-bottom: 20px;
}

/* Word count styling */
.word-count {
  font-size: 0.9rem;
  color: grey;
}

.word-limit-warning {
  color: red;
  font-size: 0.9rem;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .mock-interview {
    padding: 10px;
  }

  .hero-image {
    width: 50vw;
  }

  .hero-text {
    font-size: 1rem;
  }

  .hero-text h1 {
    font-size: 1.8rem;
  }

  .form-group {
    width: 80%;
    padding: 15px;
    margin-bottom: 15px;
  }

  textarea {
    width: 90%;
    font-size: 16px; /* Prevents zoom on focus in iOS */
    min-height: 120px;
  }

  button {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
  }

  .chat-interface {
    width: 80%;
    margin: 0 auto;
  }

  .chat-log {
    max-height: 400px;
  }

  .word-count,
  .word-limit-warning {
    font-size: 0.8rem;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .hero-image {
    width: 70vw;
  }

  .hero-text h1 {
    font-size: 1.5rem;
  }

  textarea {
    min-height: 100px;
  }

  .chat-interface {
    width: 90%;
  }

  .chat-log {
    max-height: 350px;
  }
}
</style>
