<template>
  <div class="apply-job">
    <!-- Hero Section -->
    <header v-if="job" class="hero">
      <div class="hero-content">
        <h1 class="hero-title">Apply for {{ job.title }}</h1>
        <p class="hero-subtitle">{{ job.company }}</p>
      </div>
    </header>

    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      Loading job details...
    </div>
    <!-- Error State -->
    <div v-else-if="error" class="error-state">
      {{ error }}
    </div>
    <!-- Job Details and Application Form -->
    <div v-else class="apply-job__container">
      <!-- Job Description Section -->
      <section v-if="job" class="job-description-section">
        <div class="job-description" v-html="formatDescription(job.description)"></div>
      </section>

      <!-- Application Form -->
      <section v-if="job" class="application-section">
        <h2 class="section-title">Submit Your Application</h2>
        <form @submit.prevent="submitApplication" class="application-form">
          <!-- First Name -->
          <div class="form-field">
            <label>First Name</label>
            <input v-model="applicant.firstName" required />
          </div>
          <!-- Last Name -->
          <div class="form-field">
            <label>Last Name</label>
            <input v-model="applicant.lastName" required />
          </div>
          <!-- Email -->
          <div class="form-field">
            <label>Email</label>
            <input type="email" v-model="applicant.email" required />
          </div>
          <!-- Resume -->
          <div class="form-field">
            <label>Resume</label>
            <input type="file" @change="handleFileUpload" accept=".pdf,.doc,.docx" required />
          </div>
          <!-- Submit Button -->
          <button type="submit" class="cta-btn">Submit Application</button>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { db, storage } from "@/firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { marked } from "marked";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "ApplyJob",
  data() {
    return {
      job: null,
      jobDocId: null, // To store the Firestore document ID
      applicant: {
        firstName: "",
        lastName: "",
        email: "",
      },
      resumeFile: null,
      loading: true,
      error: null,
    };
  },
  created() {
    console.log("ApplyJob component created");
    console.log("Received job docId:", this.$route.params.docId);
    this.fetchJob();
  },
  methods: {
    async fetchJob() {
      try {
        const jobDocId = this.$route.params.docId;
        console.log("Fetching job with document ID:", jobDocId);

        // Directly fetch the document using the doc ID from the route
        const jobDocRef = doc(db, "jobs", jobDocId);
        const jobDoc = await getDoc(jobDocRef);

        if (jobDoc.exists()) {
          this.jobDocId = jobDocId; // Store the document ID for later use
          this.job = { id: jobDocId, ...jobDoc.data() };

          console.log("Fetched job data:", this.job);
        } else {
          this.error = "Job not found.";
          console.error("No document found with the doc ID:", jobDocId);
        }
      } catch (error) {
        this.error = "Error fetching job details.";
        console.error("Error fetching job:", error);
      } finally {
        this.loading = false;
      }
    },
    handleFileUpload(event) {
      this.resumeFile = event.target.files[0];
    },
    async submitApplication() {
      if (!this.resumeFile) {
        alert("Please upload your resume.");
        return;
      }

      this.loading = true;

      try {
        const applicationId = uuidv4();
        console.log("Generated Application ID:", applicationId);

        if (!this.jobDocId) {
          console.error("Firestore document ID for the job is undefined");
          alert("An error occurred. Please refresh the page and try again.");
          this.loading = false;
          return;
        }

        const storageRef = ref(
          storage,
          `unauthenticated_resumes/${this.jobDocId}/applicants/${applicationId}/${this.resumeFile.name}`
        );
        await uploadBytes(storageRef, this.resumeFile);
        console.log(`Resume uploaded to path: unauthenticated_resumes/${this.jobDocId}/applicants/${applicationId}/${this.resumeFile.name}`);

        const applicationData = {
          applicationId,
          appliedOn: new Date(),
          firstName: this.applicant.firstName,
          lastName: this.applicant.lastName,
          email: this.applicant.email,
          jobId: this.job.id,
        };

        const applicantsRef = collection(db, "jobs", this.jobDocId, "applicants");
        console.log("Saving application data to Firestore path: jobs ->", this.jobDocId, "-> applicants ->", applicationId);
        await setDoc(doc(applicantsRef, applicationId), applicationData);
        console.log("Application data saved successfully!");

        alert("Application submitted successfully!");
        this.resetForm();
      } catch (error) {
        console.error("Error submitting application:", error);
        alert("An error occurred while submitting your application. Please try again.");
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.applicant = {
        firstName: "",
        lastName: "",
        email: "",
      };
      this.resumeFile = null;
    },
    formatDescription(description) {
      if (!description) return "";

      const formattedText = description
        .replace(/\\n\\n/g, "\n\n")
        .replace(/\\n/g, "\n")
        .replace(/\*\*/g, "**");

      marked.setOptions({
        breaks: true,
        gfm: true,
      });

      return marked(formattedText);
    },
  },
};
</script>

<style scoped>
/* General Styles */
.apply-job {
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: #f3ede2;
  width: 100%;
  font-family: 'FixelDisplay', sans-serif;
}

/* Hero Section */
.hero {
  background-color: #f3ede2;
  text-align: center;
  color: #333;
  padding: 50px 0;
  width: 100%;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #fc7115;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

/* Loading and Error States */
.loading-state,
.error-state {
  text-align: center;
  font-size: 1.25rem;
  color: #555;
  padding: 20px;
}

/* Job Description Section */
.job-description-section {
  background-color: #ffffff;
  padding: 40px 20px;
}

.job-description {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  color: #555;
  line-height: 1.6;
}

/* Application Form Section */
.application-section {
  background-color: #fbf7f0;
  padding: 40px 20px;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fc7115;
}

.application-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-field {
  margin-bottom: 20px;
  text-align: left;
}

.form-field label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-field input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cta-btn {
  background-color: #fc7115;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.cta-btn:hover {
  background-color: #e35d0a;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  .hero-subtitle {
    font-size: 1.2rem;
  }
  .job-description {
    padding: 0 15px;
  }
  .application-form {
    padding: 0 15px;
  }
}
</style>
