<!-- src/components/AppDownload.vue -->
<template>
  <div class="app-download">
    <p>Coming Soon: Download our app for the full experience:</p>
    <div class="download-buttons">
      <a href="https://apps.apple.com" target="_blank">
        <img src="https://via.placeholder.com/150x50?text=App+Store+Badge" alt="Download on the App Store" />
      </a>
      <a href="https://play.google.com" target="_blank">
        <img src="https://via.placeholder.com/150x50?text=Google+Play+Badge" alt="Get it on Google Play" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDownload',
};
</script>

<style scoped>
.app-download {
  background-color: #fbf7f0;
  padding: 20px;
  text-align: center;
}

.download-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.download-buttons img {
  width: 150px;
}
</style>
