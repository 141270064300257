<template>
  <div class="resume-template">
    <!-- Adjusted Hero Section -->
    <header class="hero">
      <div class="hero-background"></div> <!-- Background for the hero section -->
      <div class="hero-image-container">
        <img class="hero-image" src="@/assets/Resume_Template_Hero.png" alt="Resume Template Hero" />
      </div>
      <div class="hero-content">
        <h1 class="hero-title">Resume Template</h1>
        <p class="hero-subtitle">Prefer not to use AI to generate your resume?</p>
        <p class="hero-subtitle">Download our ATS-optimized resume template for your next application.</p>
      </div>
    </header>

    <!-- Resume Template Section -->
    <section class="template-section">
      <!-- PDF Preview -->
      <iframe src="/resume_template.pdf" frameborder="0" class="pdf-preview-frame"></iframe>

      <!-- Download Buttons -->
      <button @click="downloadDOCX">Download DOCX</button>
      <button @click="downloadPDF">Download PDF</button>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    downloadDOCX() {
      // Trigger download for DOCX without any preview
      const link = document.createElement('a');
      link.href = '/resume_template.docx';  // Path to the DOCX file in the public folder
      link.download = 'resume_template.docx';  // Suggested name for the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadPDF() {
      // Directly open the PDF for download
      window.open('/resume_template.pdf');
    },
  },
};
</script>

<style scoped>
.resume-template {
  width: 100%;
}

/* Hero Section */
.hero {
  position: relative;
  width: 100%;
  background-color: #f3ede2; /* Consistent background color */
  text-align: center;
  color: #333;
  padding: 40px 0;
  font-family: 'FixelDisplay', sans-serif;
}

.hero-background {
  background-color: #f3ede2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Hero Image Container */
.hero-image-container {
  width: 20vw;
  margin: 0 auto;
  z-index: 1;

}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px; /* Keep consistent with the rounded hero image style */
}

/* Hero Content (text) positioning */
.hero-content {
  margin-top: 20px; /* Adjust spacing from the image */
}

.hero-title {
  font-size: 2.5rem;
  color: #333;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: -10px;
}

/* Template Section */
.template-section {
  padding: 40px;
  text-align: center;
  background-color: #f3ede2;
}

button {
  background-color: #3166AA;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #274b7a;
}

/* PDF Preview Section */
.pdf-preview-frame {
  width: 100%;
  height: 600px;
  border: none;
}
/* Mobile Styles */
@media screen and (max-width: 768px) {
  .hero {
    padding: 20px 0px;
  }

  .hero-image-container {
    width: 50vw;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .template-section {
    padding: 20px 10px;
  }

  .pdf-preview-frame {
    height: 400px;
  }

  button {
    width: 100%;
    margin-top: 10px;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .hero-image-container {
    width: 70vw; /* Further increase image size on very small screens */
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .hero-subtitle {
    font-size: 0.9rem;
  }

  .pdf-preview-frame {
    height: 300px;
  }
}
</style>
