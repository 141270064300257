<!-- src/views/PrivacyPolicy.vue -->
<template>
    <div class="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>Effective Date: September 18, 2024</p>

        <p>
            At Fylter, our mission is to streamline the job search process and connect job seekers with meaningful opportunities. We highly value the trust you place in us when you share your personal information, and this privacy policy explains how we collect, use, and protect that information. By using our platform, you agree to the practices described in this policy.
        </p>

        <p>
            We periodically update this policy to reflect any changes in our data handling practices, so we encourage you to review the "Last Updated" date for recent amendments.
        </p>

        <p>
            If we implement any material changes to how we use personal data, we will notify you in advance through email or a notice on our website, providing you the option to contact us with any concerns.
        </p>

        <h2>1. Information We Collect</h2>
        <p>We collect personal data directly from you when you use our job board. This includes, but is not limited to:</p>
        <ul>
            <li><strong>Contact Information</strong>: Name, email address, phone number, and other relevant details to communicate with you and facilitate job applications.</li>
            <li><strong>Application Information</strong>: Resume, cover letters, work history, qualifications, and any other materials you provide when applying for jobs.</li>
            <li><strong>Employment Eligibility</strong>: Information related to your right to work, such as immigration status, as required by law.</li>
            <li><strong>Voluntary Demographic Data</strong>: In certain cases, we may collect information like gender, ethnicity, or military status to comply with diversity initiatives. This is completely voluntary and will not affect your job applications.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use the personal data you provide to:</p>
        <ul>
            <li>Connect you with potential employers by sharing relevant application materials.</li>
            <li>Improve and personalize your job search experience.</li>
            <li>Ensure legal compliance, such as verifying your work eligibility or conducting background checks, where necessary.</li>
            <li>Use data for analytical purposes to help us enhance the user experience on our platform.</li>
        </ul>

        <h2>3. Data Sharing</h2>
        <p>We do not sell your personal information. However, we may share your data with trusted third parties, including:</p>
        <ul>
            <li>Employers or recruiters when you apply for a job.</li>
            <li>Service providers who help us run our platform, such as hosting providers and customer support tools.</li>
            <li>Background check agencies, where applicable and in compliance with relevant laws.</li>
        </ul>

        <h2>4. Data Retention</h2>
        <p>We retain your personal information as long as necessary to provide you with our services or as required by law. You may request the deletion of your data at any time by contacting us, unless we are legally obligated to retain it for longer.</p>

        <h2>5. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
            <li>Access or request a copy of your personal information.</li>
            <li>Correct any inaccuracies in your data.</li>
            <li>Request deletion of your data, subject to legal retention requirements.</li>
            <li>Opt out of receiving non-essential communications from us.</li>
        </ul>
        <p>To exercise these rights, please contact us at <a href="mailto:support@fylterjobs.com">support@fylterjobs.com</a>.</p>

        <h2>6. Security</h2>
        <p>We implement reasonable measures to secure your personal data from unauthorized access or disclosure. However, we cannot guarantee the absolute security of information transmitted over the internet.</p>

        <h2>7. Changes to This Policy</h2>
        <p>This privacy policy may be updated from time to time. The latest version will always be available on our website, and we will notify you of any significant changes.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about how your personal information is handled, you can reach us at:</p>
        <p>Email: <a href="mailto:support@fylterjobs.com">support@fylterjobs.com</a></p>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
};
</script>

<style scoped>
.privacy-policy {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'FixelDisplay', sans-serif;
}

h1 {
    color: #3166AA;
    text-align: center;
}

h2 {
    color: #3166AA;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 20px;
    line-height: 1.6;
}

ul {
    list-style: none;
    padding-left: 0;
}

ul li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
}

ul li:before {
    content: '•';
    position: absolute;
    left: 0;
    color: #3166AA;
}

a {
    color: #3166AA;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 600px) {
    .privacy-policy {
        padding: 10px;
    }

    h1, h2 {
        font-size: 1.5em;
    }

    p, ul li {
        font-size: 1em;
    }
}
</style>
