<template>
  <div class="contact-page">
    <!-- Hero Section -->
    <header class="hero">
      <div class="hero-content">
        <h1 class="hero-title">Contact Us</h1>
        <br />
        <p class="hero-subtitle">
          We’re here to help you in your job search journey.
        </p>
      </div>
    </header>

    <!-- Contact Form Section -->
    <section class="content-section">
      <div class="container">
        <h2 class="section-title">We'd Love to Hear from You!</h2>
        <p class="section-text">
          Have questions, feedback, or need assistance with your job search?
          Fill out the form below, and we’ll get back to you as soon as
          possible.
        </p>
        <form @submit.prevent="sendContactRequest" class="contact-form">
          <div class="form-group">
            <label for="contact-reason">Reason for Contacting</label>
            <select v-model="contactReason" id="contact-reason" required>
              <option disabled value="">
                Please select your reason
              </option>
              <option>Job Search Assistance</option>
              <option>Technical Support</option>
              <option>General Inquiry</option>
            </select>
          </div>

          <div class="form-group">
            <label for="email">Your Email</label>
            <input
              type="email"
              id="email"
              v-model="email"
              placeholder="Enter your email"
              required
            />
          </div>

          <div class="form-group">
            <label for="message">Your Message</label>
            <textarea
              id="message"
              v-model="message"
              placeholder="Type your message here..."
              required
            ></textarea>
          </div>

          <button type="submit" class="submit-btn">Send Message</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
// Import Firestore instance and required Firestore functions
import { db } from '@/firebase'; // Ensure you have Firebase set up in firebase.js
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'; // Import Firestore functions

export default {
  name: 'ContactUs',
  data() {
    return {
      contactReason: '',
      email: '',
      message: '',
    };
  },
  methods: {
    async sendContactRequest() {
      try {
        // Add contact details to Firestore
        await addDoc(collection(db, 'contact_messages'), {
          contactReason: this.contactReason,
          email: this.email,
          message: this.message,
          timestamp: serverTimestamp(), // Add server timestamp
        });
        alert('Your message has been sent! We will get back to you soon.');

        // Reset the form after submission
        this.contactReason = '';
        this.email = '';
        this.message = '';
      } catch (error) {
        console.error('Error submitting contact form:', error);
        alert('An error occurred. Please try again later.');
      }
    },
  },
};
</script>

<style scoped>
/* General Page Styling */
.contact-page {
  padding: 0;
  margin: 0;
  background-color: #f3ede2;
  font-family: 'FixelDisplay', sans-serif;
}

/* Hero Section */
.hero {
  background-color: #f3ede2;
  padding: 60px 20px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  text-align: center;
}

.hero-content {
  max-width: 100vw;
  margin: 0 auto;
}

.hero-title {
  font-size: 3rem;
  color: #fc7115;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.2s;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #333;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.4s;
}

/* Contact Form Section */
.content-section {
  padding: 60px 20px;
  background-color: #fbf7f0 ;
  text-align: center;
  font-family: 'FixelDisplay', sans-serif;
}

.container {
  width: 100vw;
  margin: 0 auto;
  padding: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
}

.section-title {
  font-size: 2.5rem;
  color: #fc7115;
  margin-bottom: 20px;
}

.section-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 40px;
  font-family: 'FixelDisplay', sans-serif;
}

/* Form Styling */
.contact-form {
  text-align: center;
  
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'FixelDisplay', sans-serif;
}

.form-group label {
  display: block;
  width: 50vw; /* Set label width to 50% of the viewport */
  text-align: left;
  margin: 0 auto;
  font-weight: 600;
  font-family: 'FixelDisplay', sans-serif;
}

.form-group input,
.form-group select,
.form-group textarea,
.submit-btn {
  width: 50vw; /* Set width to 50% of the viewport */
  max-width: 100%;
  margin: 0 auto;
  display: block;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: 'FixelDisplay', sans-serif;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-btn {
  background-color: #0056b3;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.submit-btn:hover {
  background-color: #004494;
}

/* Fade-in animation */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .contact-page {
    padding: 10px;
  }

  .hero {
    padding: 40px 20px;
    min-height: 40vh;
  }

  .hero-content {
    font-size: 1rem;
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .content-section {
    width: 80%;
    padding: 15px;
    margin: 0 auto 15px;
  }

  .container {
    width: 100%;
    padding: 15px;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-text {
    font-size: 1rem;
    line-height: 1.5;
  }

  .form-group label,
  .form-group input,
  .form-group select,
  .form-group textarea,
  .submit-btn {
    width: 90%;
    font-size: 16px; /* Prevents zoom on focus in iOS */
  }

  textarea {
    min-height: 120px;
  }

  .submit-btn {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .content-section {
    width: 90%;
  }

  textarea {
    min-height: 100px;
  }
}
</style>
