<template>
  <div class="mockup-section">
    <!-- Hero Section -->
    <h2 class="section-title">Find the Perfect Job with Fylter</h2>
    <p class="section-subtitle">Fylter's mobile based job board makes applying a breeze.</p>



    <!-- First Mockup Section -->
    <div class="mockup-container" id="mockup1">
      <div class="circle"></div>
      <div class="phone">
        <div class="phone-app-bar">
          <span>Fylter Jobs</span>
        </div>
        <div class="phone-content">
          <div
            class="job-example"
            ref="jobCard"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
            @mousedown="onMouseDown"
            :style="{ transform: `translateX(${translateX}px) rotate(${rotate}deg)` }"
          >
            <!-- Job Card Content -->
            <img :src="currentJob.logo" alt="Company Logo" class="company-logo" />
            <h3>{{ currentJob.title }}</h3>
            <h4 class="company-name">{{ currentJob.company }}</h4>
            <p class="job-location">{{ currentJob.location }}</p>
            <p class="job-salary">{{ currentJob.salary }}</p>
            <p class="job-description">{{ currentJob.description }}</p>
            <!-- Swipe Indicator -->
            <div class="swipe-indicator" :class="swipeDirection" :style="{ opacity: swipeOpacity }">
              {{ swipeText }}
            </div>
          </div>
        </div>
      </div>
      <div class="bubble">
        <h4>Swipe to Apply</h4>
        <p>Swipe right to apply or left to pass, making job hunting engaging.</p>
      </div>
    </div>

    <!-- Second Mockup Section -->
    <div class="mockup-container reverse" id="mockup2">
      <div class="circle2"></div>
      <div class="phone">
        <div class="phone-app-bar">
          <span>Fylter Filters</span>
        </div>
        <div class="phone-content">
          <!-- Filtering Interface -->
          <div class="filter-interface">
            <!-- Filter Option: Salary Range -->
            <div class="filter-option">
              <label>Salary Range</label>
              <input
                type="range"
                min="50000"
                max="200000"
                step="5000"
                v-model="salary"
                @input="updateFilteredJobs"
              />
              <span>${{ salary.toLocaleString() }}</span>
            </div>
            <!-- Filter Option: Location -->
            <div class="filter-option">
              <label>Location</label>
              <select v-model="location" @change="updateFilteredJobs">
                <option value="">Any</option>
                <option value="San Francisco, CA">San Francisco, CA</option>
                <option value="New York, NY">New York, NY</option>
                <option value="Chicago, IL">Chicago, IL</option>
                <option value="Seattle, WA">Seattle, WA</option>
                <option value="Remote">Remote</option>
              </select>
            </div>
            <!-- Filter Option: Job Type -->
            <div class="filter-option">
              <label>Job Type</label>
              <select v-model="jobType" @change="updateFilteredJobs">
                <option value="">Any</option>
                <option value="Full-Time">Full-Time</option>
                <option value="Part-Time">Part-Time</option>
                <option value="Contract">Contract</option>
                <option value="Internship">Internship</option>
              </select>
            </div>
            <!-- Filter Option: Remote Jobs Only -->
            <div class="filter-option">
              <label>
                <input type="checkbox" v-model="remoteOnly" @change="updateFilteredJobs" />
                Remote Jobs Only
              </label>
            </div>
          </div>
          <!-- Filtered Jobs Preview -->
          <div class="filtered-jobs">
            <h3>Filtered Jobs</h3>
            <ul>
              <li v-for="job in filteredJobs" :key="job.title">
                <strong>{{ job.title }}</strong> - {{ job.company }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bubble">
        <h4>Advanced Filtering</h4>
        <p>Use multiple filters to find jobs that perfectly match your criteria.</p>
      </div>
    </div>

    <!-- Target Section for Smooth Scrolling -->
    <div id="target-section">
      <!-- Content for the section you scroll to -->
      <!-- Add content here -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MockupSection",
  data() {
    return {
      // Swiping data properties for the first mockup
      startX: 0,
      currentX: 0,
      translateX: 0,
      rotate: 0,
      isDragging: false,
      swipeText: "",
      swipeDirection: "",
      swipeOpacity: 0,
      // List of jobs for the swiping feature
      jobs: [
        {
          title: "Software Engineer",
          company: "Fylter Jobs",
          location: "San Francisco, CA",
          salary: "$120,000 - $140,000",
          description: "Develop innovative software solutions with cutting-edge technologies.",
          logo: require("@/assets/logo.png"),
        },
        {
          title: "UI/UX Designer",
          company: "Fylter Jobs",
          location: "New York, NY",
          salary: "$80,000 - $100,000",
          description: "Design intuitive user interfaces for our applications.",
          logo: require("@/assets/logo.png"),
        },
        {
          title: "Project Manager",
          company: "Fylter Jobs",
          location: "Chicago, IL",
          salary: "$90,000 - $110,000",
          description: "Manage projects efficiently and lead teams to success.",
          logo: require("@/assets/logo.png"),
        },
        {
          title: "DevOps Engineer",
          company: "Fylter Jobs",
          location: "Seattle, WA",
          salary: "$110,000 - $130,000",
          description: "Maintain and improve our infrastructure and deployment pipelines.",
          logo: require("@/assets/logo.png"),
        },
      ],
      currentJobIndex: 0,

      // Data properties for the second mockup (Advanced Filtering)
      salary: 100000,
      location: "",
      jobType: "",
      remoteOnly: false,
      allJobs: [
        {
          title: "Software Engineer",
          company: "Tech Corp",
          location: "San Francisco, CA",
          salary: 130000,
          jobType: "Full-Time",
          remote: false,
        },
        {
          title: "UI/UX Designer",
          company: "Design Studio",
          location: "Remote",
          salary: 90000,
          jobType: "Contract",
          remote: true,
        },
        {
          title: "Project Manager",
          company: "Business Solutions",
          location: "Chicago, IL",
          salary: 105000,
          jobType: "Full-Time",
          remote: false,
        },
        {
          title: "DevOps Engineer",
          company: "CloudNet",
          location: "Remote",
          salary: 115000,
          jobType: "Full-Time",
          remote: true,
        },
        // ... more job entries ...
      ],
      filteredJobs: [],
    };
  },
  computed: {
    currentJob() {
      return this.jobs[this.currentJobIndex];
    },
  },
  mounted() {
    // Intersection Observer for scroll animations
    const options = {
      root: null,
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    const elementsToObserve = document.querySelectorAll(
      ".mockup-container, .section-title, .section-subtitle, .cta-button"
    );
    elementsToObserve.forEach((element) => {
      observer.observe(element);
    });

    // Add event listeners for mouse events
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("mouseup", this.onMouseUp);

    // Initialize filtered jobs for the second mockup
    this.updateFilteredJobs();
  },
  beforeUnmount() {
    // Clean up event listeners when the component is destroyed
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
  },
  methods: {
    scrollToSection() {
    const signUpSection = document.getElementById('sign-up'); // Update to the ID of the target section
    if (signUpSection) {
      this.smoothScrollTo(signUpSection, 1500);
    }
  },
  smoothScrollTo(target, duration) {
    const startY = window.pageYOffset;
    const targetY = target.getBoundingClientRect().top + startY;
    const distanceY = targetY - startY;
    let startTime = null;

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startY, distanceY, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    requestAnimationFrame(animation);
    },
    // Touch event handlers for the first mockup
    onTouchStart(event) {
      this.isDragging = true;
      this.startX = event.touches[0].clientX;
    },
    onTouchMove(event) {
      if (!this.isDragging) return;
      this.currentX = event.touches[0].clientX;
      const deltaX = this.currentX - this.startX;
      this.translateX = deltaX;
      this.rotate = deltaX / 20;

      // Update swipe text and direction
      if (deltaX > 0) {
        this.swipeText = "Apply";
        this.swipeDirection = "right";
        this.swipeOpacity = Math.min(deltaX / 100, 1);
      } else if (deltaX < 0) {
        this.swipeText = "Pass";
        this.swipeDirection = "left";
        this.swipeOpacity = Math.min(-deltaX / 100, 1);
      } else {
        this.swipeText = "";
        this.swipeDirection = "";
        this.swipeOpacity = 0;
      }
    },
    onTouchEnd() {
      this.endSwipe();
    },
    // Mouse event handlers for the first mockup
    onMouseDown(event) {
      this.isDragging = true;
      this.startX = event.clientX;
    },
    onMouseMove(event) {
      if (!this.isDragging) return;
      this.currentX = event.clientX;
      const deltaX = this.currentX - this.startX;
      this.translateX = deltaX;
      this.rotate = deltaX / 20;

      // Update swipe text and direction
      if (deltaX > 0) {
        this.swipeText = "Apply";
        this.swipeDirection = "right";
        this.swipeOpacity = Math.min(deltaX / 100, 1);
      } else if (deltaX < 0) {
        this.swipeText = "Pass";
        this.swipeDirection = "left";
        this.swipeOpacity = Math.min(-deltaX / 100, 1);
      } else {
        this.swipeText = "";
        this.swipeDirection = "";
        this.swipeOpacity = 0;
      }
    },
    onMouseUp() {
      if (this.isDragging) {
        this.endSwipe();
      }
    },
    endSwipe() {
      this.isDragging = false;
      if (Math.abs(this.translateX) > 100) {
        // Swipe detected
        const direction = this.translateX > 0 ? "right" : "left";
        this.handleSwipe(direction);
      } else {
        // Reset position
        this.translateX = 0;
        this.rotate = 0;
        this.swipeOpacity = 0;
        this.swipeText = "";
        this.swipeDirection = "";
      }
    },
    handleSwipe(direction) {
      // Animate the card off the screen
      this.translateX = direction === "right" ? 500 : -500;
      this.rotate = direction === "right" ? 45 : -45;

      // After animation, reset the card
      setTimeout(() => {
        this.translateX = 0;
        this.rotate = 0;
        this.swipeOpacity = 0;
        this.swipeText = "";
        this.swipeDirection = "";
        // Load a new job card
        this.loadNewJob();
      }, 300);
    },
    loadNewJob() {
      // Move to the next job in the list
      this.currentJobIndex = (this.currentJobIndex + 1) % this.jobs.length;
    },
    // Method to update filtered jobs based on selected filters for the second mockup
    updateFilteredJobs() {
      this.filteredJobs = this.allJobs.filter((job) => {
        const matchesSalary = job.salary <= this.salary;
        const matchesLocation = this.location
          ? job.location === this.location || (this.location === "Remote" && job.remote)
          : true;
        const matchesJobType = this.jobType ? job.jobType === this.jobType : true;
        const matchesRemote = this.remoteOnly ? job.remote : true;
        return matchesSalary && matchesLocation && matchesJobType && matchesRemote;
      });
    },
  },
};
</script>

<style scoped>
.mockup-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #fbf7f0 ;
  overflow: hidden;
  
}

/* Section titles and subtitles */
.section-title {
  font-size: 2rem;
  color: #3166AA;
  margin-bottom: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.section-title.in-view {
  opacity: 1;
  transform: translateY(0);
}

.section-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.section-subtitle.in-view {
  opacity: 1;
  transform: translateY(0);
}

/* CTA Button */
.cta-button {
  background-color: #3166AA;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 50px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  animation: pulse 2s infinite;
}

.cta-button.in-view {
  opacity: 1;
  transform: translateY(0);
}

.cta-button:hover {
  background-color: #274b7a;
}

/* Pulsing effect for CTA button */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(49, 102, 170, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(49, 102, 170, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(49, 102, 170, 0);
  }
}

/* Mockup container for the phone and bubble */
.mockup-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 60px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  margin-left: -100px; /* Shift first mockup to the left */
}

.mockup-container.in-view {
  opacity: 1;
  transform: translateY(0);
}

.mockup-container.reverse {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: -100px; /* Shift second mockup to the right */
}

/* Dashed outline circle */
.circle,
.circle2 {
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 2px dashed #b0b0b0;
  background-color: transparent;
  z-index: 0;
  top: 50px;
}

.circle {
  left: calc(50% - 200px - 100px); /* Adjusted for left shift */
}

.circle2 {
  left: calc(50% - 200px + 100px); /* Adjusted for right shift */
}

/* Phone mockup */
.phone {
  background-color: #f9f9f9;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 500px;
  display: flex;
  flex-direction: column;
  border: 2px solid #e0e0e0;
  z-index: 1;
  overflow: hidden;
}

.phone:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

/* Phone app bar */
.phone-app-bar {
  background-color: #e6e6e6;
  color: #333;
  text-align: center;
  padding: 12px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Center the content inside the phone */
.phone-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  position: relative;
  overflow-y: auto;
  overflow: hidden;
}

/* Job card styles for the first mockup */
.job-example {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-out;
  touch-action: pan-y;
  position: relative;
}

.job-example:active {
  cursor: grabbing;
}

.company-logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 10px;
}

.job-example h3 {
  font-size: 1.2rem;
  color: #3166AA;
  margin-bottom: 5px;
}

.company-name {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.job-location,
.job-salary {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 5px;
}

.job-description {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  margin-top: 10px;
}

/* Swipe Indicator Styles */
.swipe-indicator {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  font-weight: bold;
  color: green;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.swipe-indicator.left {
  color: red;
}

.swipe-indicator.right {
  color: green;
}

/* Styles for the filter interface in the second mockup */
.filter-interface {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.filter-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filter-option label {
  font-size: 0.9rem;
  color: #3166AA;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.filter-option input[type="range"] {
  width: 100%;
}

.filter-option select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.filter-option input[type="checkbox"] {
  margin-right: 8px;
}

.filter-option span {
  font-size: 0.9rem;
  color: #777;
  margin-top: 5px;
}

/* Styles for the filtered jobs preview */
.filtered-jobs {
  margin-top: 20px;
  width: 100%;
}

.filtered-jobs h3 {
  font-size: 1rem;
  color: #3166AA;
  margin-bottom: 10px;
}

.filtered-jobs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.filtered-jobs li {
  font-size: 0.9rem;
  color: #333;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

/* Description bubble */
.bubble {
  background-color: #f1faff;
  border-radius: 15px;
  padding: 20px;
  width: 220px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.bubble h4 {
  font-size: 1.2rem;
  color: #3166AA;
  margin-bottom: 10px;
}

.bubble p {
  font-size: 0.9rem;
  color: #666;
  text-align: left;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .mockup-container,
  .mockup-container.reverse {
    flex-direction: column;
    gap: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .bubble {
    margin-top: 20px;
  }

  .circle,
  .circle2 {
    width: 300px;
    height: 300px;
    left: calc(50% - 150px);
  }
}
</style>
