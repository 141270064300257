<template>
    <div class="interview-help-page">
      <!-- Resume Upload Section -->
      <div class="upload-resume">
        <h2>Upload Your Resume</h2>
        <input type="file" @change="handleFileUpload" accept=".pdf,.doc,.docx" />
        <div class="drop-area" @drop.prevent="handleFileDrop" @dragover.prevent>
          <p>Drag and drop your resume here</p>
        </div>
      </div>
  
      <!-- Job Description Input Section -->
      <div class="job-description">
        <h2>Paste Job Description</h2>
        <textarea v-model="jobDescription" placeholder="Paste the job description here"></textarea>
      </div>
  
      <!-- Generate Cheat Sheet Section -->
      <div class="cheat-sheet">
        <button @click="generateCheatSheet">Generate Interview Cheat Sheet</button>
        <div v-if="cheatSheet">
          <h3>Technical Questions</h3>
          <ul>
            <li v-for="question in cheatSheet.technical" :key="question">{{ question }}</li>
          </ul>
          <h3>Behavioral Questions</h3>
          <ul>
            <li v-for="question in cheatSheet.behavioral" :key="question">{{ question }}</li>
          </ul>
          <h3>Background Questions</h3>
          <ul>
            <li v-for="question in cheatSheet.background" :key="question">{{ question }}</li>
          </ul>
        </div>
      </div>
  
      <!-- Interactive Interview Section -->
      <div class="chat-interview">
        <h2>Interactive Interview</h2>
        <div class="chat-window">
          <div v-for="message in chatHistory" :key="message.id" :class="message.role">
            <p>{{ message.content }}</p>
          </div>
        </div>
        <input v-model="userMessage" placeholder="Type your response..." @keyup.enter="sendMessage" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        resumeFile: null,
        jobDescription: '',
        cheatSheet: null,
        chatHistory: [],
        userMessage: '',
      };
    },
    methods: {
      handleFileUpload(event) {
        this.resumeFile = event.target.files[0];
      },
      handleFileDrop(event) {
        this.resumeFile = event.dataTransfer.files[0];
      },
      async generateCheatSheet() {
        // API call to back-end for generating cheat sheet
        const response = await fetch('/api/generate-cheat-sheet', {
          method: 'POST',
          body: JSON.stringify({
            resume: this.resumeFile, // Add resume processing here
            jobDescription: this.jobDescription,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        this.cheatSheet = data;
      },
      async sendMessage() {
        if (!this.userMessage) return;
  
        // Add user message to chat history
        this.chatHistory.push({ id: Date.now(), content: this.userMessage, role: 'user' });
  
        // Send message to ChatGPT
        const response = await fetch('/api/chat', {
          method: 'POST',
          body: JSON.stringify({ message: this.userMessage }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
  
        // Add GPT's response to chat history
        this.chatHistory.push({ id: Date.now(), content: data.reply, role: 'assistant' });
  
        // Clear user input
        this.userMessage = '';
      },
    },
  };
  </script>
  
  <style scoped>
  /* Basic styling for layout */
  .interview-ai-page {
    padding: 20px;
  }
  
  .upload-resume, .job-description, .cheat-sheet, .chat-interview {
    margin-bottom: 30px;
  }
  
  .drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .chat-window {
    height: 300px;
    border: 1px solid #ccc;
    overflow-y: scroll;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .chat-window .user {
    text-align: right;
    color: blue;
  }
  
  .chat-window .assistant {
    text-align: left;
    color: green;
  }
  </style>
  