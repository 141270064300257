<template>
  <div class="resume-builder">
    <!-- Background container for full width -->
    <div class="hero-background"></div>

    <!-- Hero Image -->
    <div class="hero-image">
      <img src="@/assets/Generate_Customized_Resume_Hero.png" alt="Generate Customized Resume Hero" />
    </div>
    <!-- Centered Header Section -->
    <div class="hero-text">
      <h1>Generate Customized Resume</h1>
      <p>Fill in your details below to generate a professional resume that works.</p>
    </div>

    <!-- FAQ Section Container -->
    <div class="faq-container">
  <section class="faq-section">
    <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
      <div 
        @click="toggleSection(index)" 
        :class="{'dropdown-header': true, 'is-open': isOpen[index]}"
      >
        <h3>{{ faq.question }}</h3>
        <span>{{ isOpen[index] ? '▲' : '▼' }}</span>
      </div>
      <div v-if="isOpen[index]" class="dropdown-content">
        <p>{{ faq.answer }}</p>
      </div>
    </div>
  </section>
</div>



    <!-- Generate Customized Resume Form Section -->
    <section class="form-section">
      <h2 class="form-title">Start Creating Your Resume</h2>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <h2 class="section-title">Desired Job Title</h2>
          <input type="text" v-model="formData.desiredJobTitle" placeholder="Enter your desired job title" required />
          <span v-if="errors.desiredJobTitle" class="error-message">{{ errors.desiredJobTitle }}</span>
        </div>

        <!-- Personal Information -->
        <div class="form-group">
          <h2 class="section-title">Personal Information</h2>
          <input type="text" v-model="formData.name" placeholder="Full Name" required />
          <input type="email" v-model="formData.email" placeholder="Email Address" required />
          <input type="tel" v-model="formData.phone" placeholder="Phone Number" required />
          <input type="text" v-model="formData.location" placeholder="Location (City, State)" required />
        </div>

        <!-- Work Experience -->
        <div class="form-group">
          <h2 class="section-title">Work Experience</h2>
          <div v-for="(experience, index) in formData.workExperiences" :key="index" class="experience-entry">
            <input type="text" v-model="experience.company" placeholder="Company Name" required />
            <input type="text" v-model="experience.location" placeholder="Location (City, State)" required />
            <input type="text" v-model="experience.position" placeholder="Position/Title" required />

            <div class="date-fields">
              <div class="date-field">
                <label for="startDate">Start Date</label>
                <input type="date" id="startDate" v-model="experience.startDate" required />
              </div>
              <div class="date-field">
                <label for="endDate">End Date (or Present)</label>
                <input type="date" id="endDate" v-model="experience.endDate" :disabled="experience.isPresent" />
                <label>
                  <input type="checkbox" v-model="experience.isPresent" /> Present
                </label>
              </div>
            </div>

            <div class="responsibilities-group">
              <h3>Responsibilities and Key Achievements</h3>
              <ul>
                <li v-for="(bullet, bulletIndex) in experience.responsibilities" :key="bulletIndex">
                  <input 
                    type="text" 
                    v-model="experience.responsibilities[bulletIndex]" 
                    placeholder="e.g., 'Led a team of 5 to achieve...'" 
                  />
                  <button type="button" @click="removeResponsibility(index, bulletIndex)">
                    <i class="fas fa-trash-alt"></i> Remove
                  </button>
                </li>
                <li>
                  <button type="button" @click="addResponsibility(index)">
                    <i class="fas fa-plus-circle"></i> Add Responsibility
                  </button>
                </li>
              </ul>
            </div>

            <button type="button" @click="removeWorkExperience(index)">
              <i class="fas fa-times-circle"></i> Remove Work Experience
            </button>
          </div>

          <button type="button" @click="addWorkExperience">
            <i class="fas fa-briefcase"></i> Add Work Experience
          </button>
        </div>

        <!-- Education -->
        <div class="form-group">
  <h2 class="section-title">Education</h2>
  <div v-for="(education, index) in formData.educationEntries" :key="index" class="education-entry">
    <select v-model="education.degreeType" required>
      <option disabled value="">Select Degree Type</option>
      <option value="Bachelor's">Bachelor's</option>
      <option value="Master's">Master's</option>
      <option value="PhD">PhD</option>
      <option value="Associate">Associate</option>
      <option value="Diploma">Diploma</option>
      <option value="Certificate">Certificate</option>
    </select>
    <input type="text" v-model="education.major" placeholder="Major (e.g., Computer Science)" required />
    <input type="text" v-model="education.institution" placeholder="Institution Name" required />
    <input type="text" v-model="education.location" placeholder="Location (City, State)" />

    <!-- Graduation Date Field with Label and Icon -->
    <div class="graduation-date-field">
      <label for="graduationDate">
        <i class="fas fa-calendar-alt"></i> Graduation Date
      </label>
      <input type="date" id="graduationDate" v-model="education.graduationDate" required />
    </div>

    <button type="button" @click="removeEducationEntry(index)">
      <i class="fas fa-trash-alt"></i> Remove Education
    </button>
  </div>

  <button type="button" @click="addEducationEntry">
    <i class="fas fa-plus-circle"></i> Add Education Entry
  </button>
</div>


        <!-- Skills -->
        <div class="form-group">
          <h2 class="section-title">Skills</h2>
          <div class="skills-input">
            <div v-for="(skill, index) in formData.skills" :key="index" class="skill-tag">
              <span>{{ skill }}</span>
              <button type="button" @click="removeSkill(index)">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <input type="text" v-model="newSkill" @keyup.enter="addSkill" placeholder="Enter skills relevant to your job title" />
            <button type="button" @click="addSkill">
              <i class="fas fa-plus"></i> Add Skill
            </button>
          </div>
        </div>

        <button type="submit" class="cta-btn" :disabled="isLoading">
          <span v-if="!isLoading">Generate My Resume</span>
          <span v-else>Generating...</span>
        </button>
      </form>
    </section>

    <!-- Resume Preview Section -->
    <section v-if="resumePreview" class="resume-preview">
      <h2 class="section-title">Your Resume Preview</h2>
      <iframe :src="pdfPreviewUrl" frameborder="0" class="resume-preview-frame"></iframe>
      <button @click="downloadDocx" class="cta-btn">
        <i class="fas fa-file-download"></i> Download DOCX
      </button>
    </section>
  </div>
</template>



<script>
export default {
  name: 'GenerateCustomizedResume',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        location: '',
        desiredJobTitle: '',
        workExperiences: [
        ],
        educationEntries: [
        ],
        skills: [],
      },
      newSkill: '',
      isLoading: false,
      errors: {},
      resumePreview: false,
      pdfPreviewUrl: '', // To store the PDF URL for preview
      docxDownloadUrl: '', // To store the DOCX URL for download
      isOpen: {}, // Updated to store open/close state of FAQs
      faqs: [
        {
          question: "Do I need to tailor a resume for every job?",
          answer: "Not at all! Instead of creating a new resume for each job, focus on crafting high-quality resumes tailored to the broader domains you're interested in, such as management or individual contributor work."
        },
        {
          question: "Can I rely solely on AI to create my resume?",
          answer: "AI can generate a strong foundation, but human input is still crucial. AI alone may miss important details or nuances unique to your experience."
        },
        {
          question: "Can AI-generated resumes help with Applicant Tracking Systems (ATS)?",
          answer: "Yes and No. AI resumes are often structured well for ATS, but advanced systems can sometimes detect AI-written content, which may flag the resume."
        },
        {
          question: "Why should I still review my AI-generated resume?",
          answer: "AI excels at structuring content, but you know your experience best. Reviewing ensures your resume reflects your personal strengths."
        }
      ]
    };
  },
  methods: {
    toggleSection(index) {
      if (this.isOpen[index] === undefined) {
        // Initialize the value if it hasn't been set yet
        this.isOpen[index] = true;
      } else {
        // Toggle the value
        this.isOpen[index] = !this.isOpen[index];
      }
    },
    addWorkExperience() {
      this.formData.workExperiences.push({
        company: '',
        location: '',
        position: '',
        startDate: '',
        endDate: '',
        responsibilities: [''],
      });
    },
    removeWorkExperience(index) {
      this.formData.workExperiences.splice(index, 1);
    },
    addResponsibility(experienceIndex) {
      this.formData.workExperiences[experienceIndex].responsibilities.push('');
    },
    removeResponsibility(experienceIndex, responsibilityIndex) {
      this.formData.workExperiences[experienceIndex].responsibilities.splice(responsibilityIndex, 1);
    },
    addEducationEntry() {
      this.formData.educationEntries.push({
        degreeType: '',
        major: '',
        institution: '',
        location: '',
        graduationDate: '',
      });
    },
    removeEducationEntry(index) {
      this.formData.educationEntries.splice(index, 1);
    },
    addSkill() {
      if (this.newSkill.trim()) {
        this.formData.skills.push(this.newSkill.trim());
        this.newSkill = '';
      }
    },
    removeSkill(index) {
      this.formData.skills.splice(index, 1);
    },
    formatDate(date) {
    if (date.toLowerCase() === 'present') {
      return 'Present';
    }
    const options = { year: 'numeric', month: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
  },
  
  // Method to handle form submission and generate resume
  async submitForm() {
  this.isLoading = true;
  try {
    // Call backend API to generate the resume
    const response = await fetch('/generate_resume/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.formData.name,
        contact_info: `${this.formData.email} | ${this.formData.phone} | ${this.formData.location}`,
        desired_job_title: this.formData.desiredJobTitle,
        work_experience_entries: this.formData.workExperiences.map((exp) => ({
          company: exp.company,
          location: exp.location,
          position: exp.position,
          start_date: this.formatDate(exp.startDate),
          end_date: exp.endDate ? this.formatDate(exp.endDate) : 'Present',
          responsibilities: exp.responsibilities.join(', '),
        })),
        education_entries: this.formData.educationEntries.map((edu) => ({
          degree_major: `${edu.degreeType} in ${edu.major}`,
          institution: edu.institution,
          location: edu.location,
          graduation_date: this.formatDate(edu.graduationDate),
        })),
        skills: this.formData.skills.join(', '),
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate resume.');
    }

    const data = await response.json();

// Convert hex back to binary
const pdfBlob = new Blob([new Uint8Array(data.pdf_content.match(/.{1,2}/g).map(byte => parseInt(byte, 16)))], { type: 'application/pdf' });
        const docxBlob = new Blob([new Uint8Array(data.docx_content.match(/.{1,2}/g).map(byte => parseInt(byte, 16)))], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        // Preview PDF (convert blob to object URL)
        this.pdfPreviewUrl = URL.createObjectURL(pdfBlob);

        // Store DOCX blob for download
        this.docxBlob = docxBlob;

        this.resumePreview = true;

    } catch (error) {
        console.error(error);
        alert('An error occurred while generating the resume.');
    } finally {
        this.isLoading = false;
    }
},

// Method to download DOCX
async downloadDocx() {
    try {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(this.docxBlob);
        link.setAttribute('download', 'resume.docx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading DOCX:', error);
    }
}
,
},
};
</script>

<style scoped>
/* Root Styles */
.resume-builder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

/* Hero Section */
.hero-background {
  background-color: #F3EDE2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-image {
  width: 20vw;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.hero-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.hero-text {
  text-align: center;
  margin-bottom: 30px;
  font-family: 'FixelDisplay', sans-serif;
  font-size: 1.25rem;
}

/* FAQ Section */
/* Adjusted FAQ Container */
.faq-container {
  padding: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  margin: 0 auto;
  max-width: 1400px;
  background-color: #f3ede2;
  font-family: 'FixelDisplay', sans-serif;
}

/* Dropdown Styles */
.dropdown-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fbf7f0;
  padding: 20px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 5px; /* Fully rounded */
  font-size: 1.6rem;
  box-sizing: border-box;
  margin-bottom: 20px; /* Add space between sections */
  width: 70vw;

}

.dropdown-header h3 {
  margin: 0;
  font-size: 1.6rem;
  color: #333333;
}

.dropdown-content {
  padding: 20px 30px;
  background-color: #fbf7f0;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-top: none; /* Merge with header */
  border-radius: 0 0 5px 5px; /* Only bottom corners rounded */
  font-size: 1.4rem;
  line-height: 1.6;
  box-sizing: border-box;
  margin-bottom: 20px; /* Space after each section */
  margin-top: -20px; /* Collapse space between header and content */
}

/* When the dropdown is open, merge header and content */
.dropdown-header.is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0; /* Remove space between header and content */
}



.dropdown-content ul {
  list-style: disc inside;
  margin-left: 20px;
  font-size: 1.4rem;
}

/* FAQ Item Spacing */
.faq-item {
  width: 100%;
  margin-bottom: 10px;
}

/* Form Section */
.form-section {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto 50px auto;
  padding: 40px;
  background-color: #fbf7f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.form-title {
  font-size: 2.5rem;
  color: #E07B3C;
  margin-bottom: 30px;
  text-align: center;
  font-family: 'FixelDisplay', sans-serif;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  font-family: 'FixelDisplay', sans-serif;
}

/* Two-column layout for Personal Information */
.personal-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.personal-info input {
  flex: 1;
  min-width: calc(50% - 10px);
}

/* Work Experience & Education Entries */
.experience-entry, .education-entry {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Date Fields in a Single Row */
.date-fields {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.date-field {
  flex: 1;
}

.date-field label {
  display: block;
  margin-bottom: 5px;
}

/* Responsibilities Group */
.responsibilities-group {
  margin-top: 20px;
}

.responsibilities-group ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.responsibilities-group ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* Skills Section */
.skills-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.skill-tag {
  background-color: #e0e0e0;
  padding: 8px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.skill-tag button {
  background: none;
  border: none;
  font-size: 1rem;
  margin-left: 5px;
  cursor: pointer;
}

.skill-tag button:hover {
  color: red;
}

/* Buttons */
button {
  background-color: #E07B3C;
  color: #fff;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #D2691E;
}

button:focus {
  outline: 2px solid #3166AA;
  outline-offset: 2px;
}

/* Error Message */
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
}

/* Resume Preview Section */
.resume-preview {
  width: 80%;
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.resume-preview-frame {
  width: 100%;
  height: 600px;
  margin-bottom: 20px;
}

/* Input Fields Styling */
input[type='text'], input[type='email'], input[type='tel'], 
input[type='date'], textarea, select {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'FixelDisplay', sans-serif;
  box-sizing: border-box;
}

textarea {
  min-height: 80px;
}

/* Responsive Design */
/* Mobile Styles */
@media screen and (max-width: 768px) {
  .resume-builder {
    padding: 10px;
  }

  .hero-image {
    width: 50vw;
  }

  .hero-text {
    font-size: 1rem;
  }

  .hero-text h1 {
    font-size: 1.8rem;
  }

  .faq-container {
    width: 90%;
    padding: 20px 10px;
  }

  .dropdown-header {
    width: 100%;
    font-size: 1.2rem;
    padding: 15px;
  }

  .dropdown-header h3 {
    font-size: 1.2rem;
  }

  .dropdown-content {
    width: 100%;
    font-size: 1rem;
    padding: 15px;
  }

  .form-section {
    width: 80%;
    padding: 20px;
  }

  .form-title {
    font-size: 2rem;
  }

  .personal-info {
    flex-direction: column;
  }

  .personal-info input {
    width: 100%;
  }

  .date-fields {
    flex-direction: column;
  }

  .skills-input {
    flex-direction: column;
  }

  button {
    width: 100%;
    margin-top: 10px;
  }

  .resume-preview {
    width: 100%;
    padding: 15px;
  }

  .resume-preview-frame {
    height: 400px;
  }

  input[type='text'], input[type='email'], input[type='tel'], 
  input[type='date'], textarea, select {
    font-size: 16px; /* Prevents zoom on focus in iOS */
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .hero-image {
    width: 70vw;
  }

  .hero-text h1 {
    font-size: 1.5rem;
  }

  .form-title {
    font-size: 1.8rem;
  }

  .dropdown-header,
  .dropdown-content {
    font-size: 1rem;
  }
}
</style>

