<!-- src/views/About.vue -->
<template>
  <div class="about">
    <!-- Hero Section (Similar to Home) -->
    <header class="hero">
      <div class="hero-content">
        <h1 class="hero-title">Search Smarter, Apply Effortlessly</h1>
        <br>
        <p class="hero-subtitle">Discover how Fylter is transforming job search.</p>
      </div>
    </header>

    <!-- About Content Section -->
    <section class="content-section">
      <div class="container">
        <h2 class="section-title">Our Mission</h2>
        <p class="section-text">
          We’re here to make your job search smarter and more efficient. Fylter’s mission is to save you time and connect you directly with jobs that match your skills. 
          We believe in a fair, skill-based system that highlights your potential, instead of relying on ads that benefit only employers who pay job-boards more.
        </p>
      </div>

      <div class="container">
        <h2 class="section-title">Why Fylter?</h2>
        <ul class="feature-list">
  <li>
    <i class="fas fa-bolt"></i>
    <div>
      <strong>Quick and Easy Applications:</strong> 
      <span>Use our swipe-based platform to apply for jobs in seconds. No need to fill out lengthy forms—just swipe or tap, and you’re done!</span>
    </div>
  </li>
  <li>
    <i class="fas fa-briefcase"></i>
    <div>
      <strong>Find Jobs That Match Your Skills:</strong> 
      <span>Fylter’s smart algorithms focus on what you can do, not just job titles, helping you discover roles you might not have considered.</span>
    </div>
  </li>
  <li>
    <i class="fas fa-users"></i>
    <div>
      <strong>More Opportunities, Less Bias:</strong> 
      <span>We focus on your skills, not personal factors, making the job market more fair and inclusive for everyone.</span>
    </div>
  </li>
  <li>
    <i class="fas fa-hand-holding-usd"></i>
    <div>
      <strong>No Fees, No Gimmicks:</strong> 
      <span>You won’t pay a thing to use Fylter, and employers save money, too, meaning more focus on the right match—not keyword bidding.</span>
    </div>
  </li>
</ul>

      </div>

      <div class="container">
        <h2 class="section-title">Get in Touch</h2>
        <p class="section-text">
          Got questions? Need support? We’re here to help. Head over to our <a href="/contact">Contact Page</a> to reach out—we’d love to hear from you and help you in your job search journey!
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
name: 'AboutPage',
};
</script>

<style scoped>
.about {
padding: 0;
margin: 0;
background-color: #f3ede2;
font-family: 'FixelDisplay', sans-serif;
text-align: center;
}

/* Hero Section (Inspired by Home Page) */
.hero {
background-color: #f3ede2;
padding: 60px 20px;
min-height: 50vh;
display: flex;
justify-content: center;
align-items: center;
color: #333;
text-align: center;
}

.hero-content {
width: 100%;
margin: 0 auto;
}

.hero-title {
font-size: 3rem;
color: #fc7115;
opacity: 0;
transform: translateY(20px);
animation: fadeInUp 1s ease-out forwards;
animation-delay: 0.2s;
}

.hero-subtitle {
font-size: 1.2rem;
margin-bottom: 30px;
color: #333;
opacity: 0;
transform: translateY(20px);
animation: fadeInUp 1s ease-out forwards;
animation-delay: 0.4s;
}

/* Content Section */
.content-section {
padding: 60px 20px;
background-color: #fbf7f0 ;
text-align: center;
}

.container {
  margin: 0 auto;
  padding: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  text-align: center; /* Ensure content inside stays centered */
}

.section-title {
font-size: 2.5rem;
color: #fc7115;
margin-bottom: 20px;
text-align: center; /* Ensure content inside stays centered */
}

.section-text {
font-size: 1.2rem;
line-height: 1.6;
margin-bottom: 40px;
text-align: center; /* Ensure content inside stays centered */
}

/* Feature List Styling */
.feature-list {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

.feature-list li {
  display: flex;
  align-items: flex-start; /* Align at the top for better layout */
  margin-bottom: 30px; /* Add more vertical space between items */
}

.feature-list i {
  color: #fc7115;
  font-size: 2rem; /* Slightly larger icons */
  margin-right: 20px; /* More space between icon and text */
  min-width: 40px; /* Ensure consistent spacing for icons */
  text-align: center;
}

.feature-list strong {
  font-size: 1.2rem; /* Increase font size for headings */
  margin-right: 5px; /* Add space between heading and explanation */
  font-weight: bold;
}

.feature-list span {
  font-size: 1.1rem; /* Slightly larger body text */
  line-height: 1.8; /* Better line spacing for readability */
}

/* Fade-in animation */
@keyframes fadeInUp {
to {
  opacity: 1;
  transform: translateY(0);
}
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .hero {
    padding: 40px 15px;
    min-height: 40vh;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .content-section {
    padding: 40px 15px;
  }

  .container {
    padding: 15px;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-text {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  .feature-list li {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;
  }

  .feature-list i {
    font-size: 1.8rem;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .feature-list strong {
    font-size: 1.1rem;
    display: block;
    margin-bottom: 5px;
  }

  .feature-list span {
    font-size: 1rem;
    line-height: 1.6;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 1.8rem;
  }

  .hero-subtitle {
    font-size: 0.9rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-text {
    font-size: 0.9rem;
  }

  .feature-list i {
    font-size: 1.6rem;
  }

  .feature-list strong {
    font-size: 1rem;
  }

  .feature-list span {
    font-size: 0.9rem;
  }
}
</style>
