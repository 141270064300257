<template>
  <div class="mock-interview">
    <!-- Background container for full width -->
    <div class="hero-background"></div>

    <!-- Hero Image -->
    <div class="hero-image">
      <img src="@/assets/Interview_Hero.png" alt="Interview Hero">
    </div>

    <!-- Centered Hero Text -->
    <div class="hero-text">
      <h1>Mock Interview</h1>
      <p>Simulate an interview based on your resume and the job description using AI.</p>
    </div>

    <!-- Job Description and Resume Input -->
    <div v-if="!sessionStarted" class="form-group">
      <h2>Job Description</h2>
      <textarea 
        v-model="jobDescription" 
        placeholder="Enter the job description here" 
        rows="5" 
        @input="checkJobDescriptionWordCount">
      </textarea>
      <!-- Word count display and warning -->
      <p :class="{'word-limit-warning': jobDescriptionWordCount > 600, 'word-count': jobDescriptionWordCount <= 600}">
        {{ jobDescriptionWordCount }}/600
      </p>
      <p v-if="jobDescriptionWordCount > 600" class="word-limit-warning">
        The job description exceeds 600 words. Please shorten it.
      </p>

      <h2>Resume Text (Optional)</h2>
      <textarea 
        v-model="resumeText" 
        placeholder="Paste your resume text here (optional)" 
        rows="5" 
        @input="checkResumeTextWordCount">
      </textarea>
      <!-- Word count display and warning for resume text -->
      <p :class="{'word-limit-warning': resumeTextWordCount > 600, 'word-count': resumeTextWordCount <= 600}">
        {{ resumeTextWordCount }}/600
      </p>
      <p v-if="resumeTextWordCount > 600" class="word-limit-warning">
        The resume text exceeds 600 words. Please shorten it.
      </p>

      <div class="button-container">
        <button @click="startInterview" :disabled="!jobDescription || jobDescriptionWordCount > 600 || resumeTextWordCount > 600 || isLoading">
          <span v-if="!isLoading">Start Interview</span>
          <span v-else>Starting...</span>
        </button>
      </div>
    </div>

    <!-- Chat Interface -->
    <div v-if="sessionStarted" class="chat-interface">
      <div class="chat-log">
        <div v-for="(message, index) in chatHistory" :key="index" :class="['chat-bubble', message.role]">
          <div class="message-content">
            <strong>{{ message.role === 'user' ? 'You' : 'Interviewer' }}:</strong>
            <p v-html="formatMessage(message.content)"></p>
          </div>
        </div>
      </div>

      <div class="chat-input">
        <textarea v-model="userResponse" placeholder="Enter your response here" rows="3"></textarea>
        <button @click="sendResponse" :disabled="!userResponse || isLoading">
          <span v-if="!isLoading">Send Response</span>
          <span v-else>Sending...</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MockInterview",
  data() {
    return {
      jobDescription: "", // For job description input
      resumeText: "", // Resume text input (optional)
      userResponse: "", // User's response to the current question
      sessionId: null, // Session ID for tracking the conversation
      chatHistory: [], // To store the conversation history
      sessionStarted: false, // To track if the interview session has started
      isLoading: false, // Control loading state
      jobDescriptionWordCount: 0, // Word count for job description
      resumeTextWordCount: 0, // Word count for resume text
    };
  },
  methods: {
    // Method to check the word count of the job description
    checkJobDescriptionWordCount() {
      this.jobDescriptionWordCount = this.jobDescription.split(/\s+/).filter(word => word.length > 0).length;
    },

    // Method to check the word count of the resume text
    checkResumeTextWordCount() {
      this.resumeTextWordCount = this.resumeText.split(/\s+/).filter(word => word.length > 0).length;
    },

    async startInterview() {
      if (this.jobDescriptionWordCount > 600 || this.resumeTextWordCount > 600) {
        alert('Either the job description or resume text exceeds the 600-word limit. Please shorten it.');
        return;
      }

      this.isLoading = true;
      try {
        // Initial API call to start the interview
        const formData = new FormData();
        formData.append("jobDescription", this.jobDescription);
        if (this.resumeText) {
          formData.append("resumeText", this.resumeText); // Add resume text if it exists
        }

        const response = await fetch("/mock_interview/", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.error) {
          alert(data.error);
        } else {
          this.sessionId = data.sessionId;
          this.chatHistory.push({ role: "interviewer", content: data.assistantResponse });
          this.sessionStarted = true;
        }
      } catch (error) {
        console.error("Error starting interview:", error);
        alert("An error occurred while starting the interview.");
      } finally {
        this.isLoading = false;
      }
    },

    async sendResponse() {
      if (!this.userResponse) return;
      this.isLoading = true;
      try {
        // Send user's response to continue the conversation
        const formData = new FormData();
        formData.append("jobDescription", this.jobDescription);
        formData.append("userResponse", this.userResponse);
        formData.append("sessionId", this.sessionId);

        const response = await fetch("/mock_interview/", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.error) {
          alert(data.error);
        } else {
          // Add user's response and assistant's new response to the chat history
          this.chatHistory.push({ role: "user", content: this.userResponse });
          this.chatHistory.push({ role: "interviewer", content: data.assistantResponse });
          this.userResponse = ""; // Clear user's input
        }
      } catch (error) {
        console.error("Error sending response:", error);
        alert("An error occurred while sending your response.");
      } finally {
        this.isLoading = false;
      }
    },

    formatMessage(message) {
      // Replace instances of double asterisks (**) with <strong> tags for bold formatting
      return message
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        .replace(/\*\*Feedback:\*\*/g, "<br><strong>Feedback:</strong>")
        .replace(/\*\*2\.\s/g, "<br><br><strong>Next Question:</strong><br>")
        .replace(/\n/g, "<br>");
    }
  }
};
</script>

<style scoped>
/* New full-width background container */
.hero-background {
  background-color: #F3EDE2; /* The desired background color */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Stretch it across the full viewport width */
  z-index: -1; /* Place it behind the hero image */
  display: flex; /* Flexbox to align the height */
  justify-content: center;
}

/* Hero image container */
.hero-image {
  width: 20vw; /* Adjust the width of the image */
  margin: 0 auto 20px auto; /* Center the image */
  position: relative;
  z-index: 1; /* Ensure it stays in front of the background */
}

/* Ensure the image scales properly inside the container */
.hero-image img {
  width: 100%; /* Make the image fit the width of the container */
  height: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the entire image is visible */
  border-radius: 10px;
}

/* Centered Hero Text Styling */
.hero-text {
  text-align: center;
  margin-bottom: 30px;
  font-family: 'FixelDisplay', sans-serif;
}

.mock-interview {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  
}

.form-group {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fbf7f0;
  width: 60vw;       /* Use a flexible width instead of min-width */
  max-width: 800px;  /* Set a max-width to prevent it from stretching too much */
  margin-left: auto;  /* Use auto margins to center the form */
  margin-right: auto;
  font-family: 'FixelDisplay', sans-serif;
}

textarea {
  display:block;
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
  resize: none; /* Enables resizing on both axes */
  min-height: 100px; /* Set a minimum height for resizing */
  margin: 0 auto;
  font-family: 'FixelDisplay', sans-serif;
}


button {
  background-color: #E07B3C;
  color: #fff;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
  font-family: 'FixelDisplay', sans-serif;
}

button:hover {
  background-color: #D2691E;
}

button:disabled {
  background-color: #999;
}

.chat-interface {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
}

.chat-log {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  font-family: 'FixelDisplay', sans-serif;
}

.chat-bubble {
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.user {
  background-color: #007aff; /* iPhone blue */
  color: white;
  align-self: flex-end;
  text-align: right;
  margin-left: auto;
}

.interviewer {
  background-color: #e5e5ea; /* iPhone grey */
  color: black;
  align-self: flex-start;
  text-align: left;
  margin-right: auto;
}

.chat-input {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds spacing between the textarea and button */
  margin-top: 10px; /* Optional: Add some top margin for additional spacing */
  font-family: 'FixelDisplay', sans-serif;
}

/* Word count styling */
.word-count {
  font-size: 0.9rem;
  color: grey;
}

.word-limit-warning {
  color: red;
  font-size: 0.9rem;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adds some space above the button */
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .about {
    padding: 10px;
  }

  .hero-image {
    width: 50vw;
  }

  .hero-content {
    font-size: 1rem;
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .content-section {
    width: 80%;
    padding: 15px;
    margin: 0 auto 15px;
  }

  .container {
    width: 100%;
    padding: 15px;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-text {
    font-size: 1rem;
    line-height: 1.5;
  }

  .feature-list {
    width: 90%;
    margin: 0 auto;
  }

  .feature-list li {
    margin-bottom: 20px;
  }

  .feature-list i {
    font-size: 1.5rem;
  }

  .feature-list strong {
    font-size: 1.1rem;
  }

  .feature-list span {
    font-size: 1rem;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .hero-image {
    width: 70vw;
  }

  .hero-title {
    font-size: 1.5rem;
  }

  .content-section {
    width: 90%;
  }

  .feature-list {
    width: 100%;
  }
}
</style>
