<template>
  <div id="app">
    <!-- App Bar (Navigation) -->
    <header class="app-bar">
      <nav class="nav-container">
        <!-- Logo and Title -->
        <div class="logo-container">
          <router-link to="/" class="logo-link">
            <img src="@/assets/logo.png" alt="Fylter Logo" class="logo" />
            <span class="brand-title">Fylter Jobs</span>
          </router-link>
        </div>

        <!-- Hamburger Menu Icon for Mobile -->
        <div class="hamburger-menu" @click="toggleMobileMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <!-- Navigation Links -->
        <div :class="['nav-links', { 'mobile-active': isMobileMenuOpen }]">
          <router-link to="/" @click="closeMobileMenu">Home</router-link>

          <!-- Resume Help with Dropdown -->
          <div class="dropdown">
            <span @click="openDropdown('resume')" class="dropdown-link">
              Resume Help
              <!-- Dropdown arrow -->
              <span class="dropdown-icon">▼</span>
            </span>
            <ul v-if="isResumeDropdownVisible" class="dropdown-menu">
              <li class="dropdown-item">
                <router-link @click="closeDropdowns" to="/resume-best-practices">
                  <div class="item-text">
                    <span class="item-title">Resume Best Practices</span>
                    <span class="item-subtext"><em>Improve your resume</em></span>
                  </div>
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link @click="closeDropdowns" to="/resume-template">
                  <div class="item-text">
                    <span class="item-title">Resume Template</span>
                    <span class="item-subtext"><em>Download Resume Template</em></span>
                  </div>
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link @click="closeDropdowns" to="/generate-customized-resume">
                  <div class="item-text">
                    <span class="item-title">Generate Customized Resume</span>
                    <span class="item-subtext"><em>Tailor a resume to your ideal job</em></span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>

          <!-- Interview Help with Dropdown -->
          <div class="dropdown">
            <span @click="openDropdown('interview')" class="dropdown-link">
              Interview Help
              <!-- Dropdown arrow -->
              <span class="dropdown-icon">▼</span>
            </span>
            <ul v-if="isInterviewDropdownVisible" class="dropdown-menu">
              <li class="dropdown-item">
                <router-link @click="closeDropdowns" to="/interview-study-guide">
                  <div class="item-text">
                    <span class="item-title">Interview Study Guide</span>
                    <span class="item-subtext"><em>Prepare for common interview questions</em></span>
                  </div>
                </router-link>
              </li>
              <li class="dropdown-item">
                <router-link @click="closeDropdowns" to="/mock-interview">
                  <div class="item-text">
                    <span class="item-title">Mock Interview</span>
                    <span class="item-subtext"><em>Practice a live interview</em></span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>

          <router-link to="/about" @click="closeMobileMenu">About Us</router-link>
        </div>
      </nav>
    </header>

    <!-- Router-view with transition -->
    <main>
      <transition :name="$route.meta.transition">
        <router-view />
      </transition>
    </main>

    <!-- Footer -->
    <footer class="enhanced-footer">
      <div class="footer-sections">
        <!-- Resume Help Section -->
        <div class="footer-column">
          <h3>Resume Help</h3>
          <router-link to="/resume-best-practices">Resume Best Practices</router-link>
          <router-link to="/resume-template">Resume Template</router-link>
          <router-link to="/generate-customized-resume">Generate Customized Resume</router-link>
        </div>

        <!-- Interview Help Section -->
        <div class="footer-column">
          <h3>Interview Help</h3>
          <router-link to="/interview-study-guide">Interview Study Guide</router-link>
          <router-link to="/mock-interview">Mock Interview</router-link>
        </div>

        <!-- Additional Info -->
        <div class="footer-column">
          <h3>Company</h3>
          <router-link to="/about">About Us</router-link>
          <router-link to="/contact">Contact Us</router-link>
        </div>
      </div>

      <!-- Bottom Section -->
      <div class="footer-bottom">
        <div class="footer-left">
          <router-link to="/privacy-policy">Privacy Policy</router-link>
        </div>
        <div class="footer-right">
          <p>&copy; 2024 Fylter LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isResumeDropdownVisible: false,
      isInterviewDropdownVisible: false,
      isMobileMenuOpen: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "auto" });
    },
    openDropdown(dropdown) {
      if (dropdown === "resume") {
        this.isResumeDropdownVisible = !this.isResumeDropdownVisible;
        this.isInterviewDropdownVisible = false;
      } else if (dropdown === "interview") {
        this.isInterviewDropdownVisible = !this.isInterviewDropdownVisible;
        this.isResumeDropdownVisible = false;
      }
    },
    closeDropdowns() {
      this.isResumeDropdownVisible = false;
      this.isInterviewDropdownVisible = false;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      if (!this.isMobileMenuOpen) {
        this.closeDropdowns();
      }
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
      this.closeDropdowns();
    },
  },
  watch: {
    $route() {
      this.closeDropdowns();
      this.closeMobileMenu();
    },
  },
};
</script>

<style scoped>
/* Global reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: "FixelDisplay";
  src: url("@/assets/fonts/FixelDisplay-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FixelDisplay";
  src: url("@/assets/fonts/FixelDisplay-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

/* Reset the html and body elements */
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  font-family: "FixelDisplay", sans-serif;
}

/* Ensure #app stretches to full height */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* App Bar (Navigation) */
.app-bar {
  background-color: #f3ede2;
  padding: 10px 40px;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  font-family: "FixelDisplay", sans-serif;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  margin-right: 10px;
}

.brand-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fc7115;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.nav-links a {
  color: #333;
  margin: 0;
  text-decoration: none;
  font-weight: 600;
}

.nav-links a:hover {
  color: #fc7115;
}

/* Dropdown styling */
.dropdown {
  position: relative;
}

.dropdown-link {
  color: #333;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-link:hover {
  color: #fc7115;
}

.dropdown-icon {
  margin-left: 5px;
  font-size: 0.8rem;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  color: #333;
  padding: 10px;
  list-style: none;
  width: 260px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 1000;
  overflow: visible;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.2s ease-in;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.item-text {
  display: flex;
  flex-direction: column;
}

.item-title {
  font-weight: 600;
  color: #333;
}

.item-subtext {
  font-size: 0.8rem;
  color: #888;
  font-style: italic;
}

/* Ensure main content takes full viewport height */
main {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column; /* Ensure proper stacking of child elements */
  align-items: center; /* Center child elements horizontally */
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-link:hover {
  text-decoration: none;
}

/* Enhanced Footer Styling */
.enhanced-footer {
  background-color: #f3ede2;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "FixelDisplay", sans-serif;
}

.footer-sections {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-column h3 {
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.footer-column a {
  margin: 5px 0;
  color: #fc7115;
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

/* Footer Bottom Section */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.footer-left a {
  color: #333;
  margin-right: 10px;
  text-decoration: none;
}

.footer-left a:hover {
  text-decoration: underline;
}

.footer-right p {
  margin: 0;
  color: #333;
}

/* Hamburger Menu Icon */
.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu span {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 2px 0;
  transition: 0.4s;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .nav-container {
    flex-wrap: wrap;
  }

  main {
    padding: 0 10px; /* Reduce padding on smaller screens */
  }

  main > * {
  max-width: 100%;
  box-sizing: border-box;
}

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
    background-color: #f3ede2;
  }

  .nav-links.mobile-active {
    display: flex;
  }

  .hamburger-menu {
    display: flex;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown-menu {
    position: relative;
    width: 100%;
    box-shadow: none;
    border: none;
    background-color: #f3ede2;
    padding: 0;
  }

  .dropdown-item {
    padding: 10px 0;
  }

  .footer-sections {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 20px;
    align-items: center;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .footer-left {
    margin-bottom: 10px;
  }
}
</style>

<!-- Transition styles should not be scoped -->
<style>
/* Fade transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>