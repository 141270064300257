<template>
  <div class="job-detail">
    <div class="job-detail__container">
      <h1 class="job-detail__title">{{ job.title }}</h1>
      <p class="job-detail__company">{{ job.company }}</p>
      <div class="job-detail__info">
        <p><MapPinIcon class="icon-class" /> {{ job.location }}</p>
        <p><DollarSignIcon class="icon-class" /> ${{ job.salaryRange.min.toLocaleString() }} - ${{ job.salaryRange.max.toLocaleString() }}</p>
        <p><BriefcaseIcon class="icon-class" /> {{ job.type }}</p>
        <p v-if="job.remote || job.globallyRemote">
          <GlobeIcon class="icon-class" /> {{ job.globallyRemote ? 'Globally Remote' : 'Remote' }}
        </p>
        <p><CalendarIcon class="icon-class" /> Posted {{ formatDate(job.datePosted) }}</p>
      </div>
      <div class="job-detail__section">
        <h2>Description</h2>
        <div v-html="formatDescription(job.description)"></div>
      </div>
      <div class="job-detail__section">
        <h2>Required Skills</h2>
        <div class="job-detail__skills">
          <span
            v-for="(skill, index) in job.requiredSkills"
            :key="index"
            class="job-detail__skill-tag"
          >
            {{ skill.skill }}
          </span>
        </div>
      </div>
      <div v-if="job.desiredSkills && job.desiredSkills.length > 0" class="job-detail__section">
        <h2>Desired Skills</h2>
        <div class="job-detail__skills">
          <span
            v-for="(skill, index) in job.desiredSkills"
            :key="`desired-${index}`"
            class="job-detail__skill-tag"
          >
            {{ skill.skill }}
          </span>
        </div>
      </div>
      <div class="job-detail__actions">
        <button 
          class="job-detail__apply"
          @click="applyToJob"
        >
          Apply Now
        </button>
        <button 
          class="job-detail__share"
          @click="shareJob"
        >
          <ShareIcon />
          Share
        </button>
        <button 
          class="job-detail__back"
          @click="goBack"
        >
          Back to Jobs
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import { 
  MapPinIcon, 
  BriefcaseIcon,
  DollarSignIcon,
  CalendarIcon,
  GlobeIcon,
  ShareIcon
} from 'lucide-vue-next';
import { marked } from 'marked';

export default {
  name: "JobDetail",
  components: {
    MapPinIcon,
    BriefcaseIcon,
    DollarSignIcon,
    CalendarIcon,
    GlobeIcon,
    ShareIcon
  },
  data() {
    return {
      job: null,
      loading: true,
      error: null
    };
  },
  methods: {
    async fetchJob() {
      const jobId = this.$route.params.id;
      try {
        const docRef = doc(db, "jobs", jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.job = { id: docSnap.id, ...docSnap.data() };
        } else {
          this.error = "Job not found.";
        }
      } catch (error) {
        this.error = "Error fetching job details.";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },
    formatDescription(description) {
      if (!description) return '';
      const formattedText = description
        .replace(/\\n\\n/g, '\n\n')
        .replace(/\\n/g, '\n')
        .replace(/\*\*/g, '**');

      marked.setOptions({
        breaks: true,
        gfm: true
      });
      
      return marked(formattedText);
    },
    applyToJob() {
    if (this.job.id) {
      this.$router.push({ name: 'JobApply', params: { id: this.job.id } });
    } else {
      alert('Job ID is not available.');
    }
  },
    shareJob() {
      // Placeholder for share functionality
      alert(`Share this job: ${this.job.title} at ${this.job.company}`);
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  created() {
    this.fetchJob();
  }
};
</script>

<style>
.job-detail {
  padding: 2rem;
}

.job-detail__container {
  max-width: 800px;
  margin: 0 auto;
}

.job-detail__title {
  font-size: 2rem;
  font-weight: 700;
  color: #fc7115;
}

.job-detail__company {
  font-weight: 500;
  color: #333;
  margin-top: 0.5rem;
}

.job-detail__info > p {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.job-detail__section {
  margin-top: 2rem;
}

.job-detail__section h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.job-detail__skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.job-detail__skill-tag {
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.job-detail__actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.job-detail__apply,
.job-detail__share,
.job-detail__back {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  border-radius: 0.5rem;
  cursor: pointer;
  border: none;
  color: white;
}

.job-detail__apply {
  background-color: #fc7115;
}

.job-detail__apply:hover {
  background-color: #e35d0a;
}

.job-detail__share {
  background-color: #3b82f6;
}

.job-detail__share:hover {
  background-color: #2563eb;
}

.job-detail__back {
  background-color: #6b7280;
}

.job-detail__back:hover {
  background-color: #4b5563;
}

/* Icon Styling */
.icon-class {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.5rem;
}
</style>
