<template>
    <div class="career-coach">
      <!-- Background container for full width -->
      <div class="hero-background"></div>
  
      <!-- Hero Image -->
      <div class="hero-image">
        <img src="@/assets/Career_Coach_Hero.png" alt="Career Coach Hero">
      </div>
  
      <!-- Centered Hero Text -->
      <div class="hero-text">
        <h1>AI Career Coach</h1>
        <p>Get personalized career advice based on your resume and career goals using AI.</p>
      </div>
  
<!-- FAQ Accordion Section -->
<section class="faq-section">
  <h2>How to Interact with Your Career Coach</h2>

  <div @click="toggleSection('careerPathAdvice')" :class="{'dropdown-header': true, 'is-open': isOpen.careerPathAdvice}">
    <h3>Career Path Advice</h3>
    <span>{{ isOpen.careerPathAdvice ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.careerPathAdvice" class="dropdown-content">
    <p>Discuss potential career paths based on your skills and experience. Get advice on transitioning to new roles or industries.</p>
  </div>

  <div @click="toggleSection('skillsDevelopment')" :class="{'dropdown-header': true, 'is-open': isOpen.skillsDevelopment}">
    <h3>Skills Development</h3>
    <span>{{ isOpen.skillsDevelopment ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.skillsDevelopment" class="dropdown-content">
    <p>Find out which skills you should develop to advance in your career, and learn about certifications or courses to help you grow.</p>
  </div>

  <div @click="toggleSection('skillsAssessment')" :class="{'dropdown-header': true, 'is-open': isOpen.skillsAssessment}">
    <h3>Skills Assessment</h3>
    <span>{{ isOpen.skillsAssessment ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.skillsAssessment" class="dropdown-content">
    <p>Ask for an evaluation of your current skills to identify areas of improvement and align them with your career goals.</p>
  </div>

  <div @click="toggleSection('jobSearchStrategies')" :class="{'dropdown-header': true, 'is-open': isOpen.jobSearchStrategies}">
    <h3>Job Search Strategies</h3>
    <span>{{ isOpen.jobSearchStrategies ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.jobSearchStrategies" class="dropdown-content">
    <p>Learn effective job search techniques and strategies, including networking and how to tailor your approach to different industries.</p>
  </div>

  <div @click="toggleSection('networkingAdvice')" :class="{'dropdown-header': true, 'is-open': isOpen.networkingAdvice}">
    <h3>Networking Advice</h3>
    <span>{{ isOpen.networkingAdvice ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.networkingAdvice" class="dropdown-content">
    <p>Discover the best practices for building and utilizing your professional network to advance your career.</p>
  </div>

  <div @click="toggleSection('salaryNegotiation')" :class="{'dropdown-header': true, 'is-open': isOpen.salaryNegotiation}">
    <h3>Salary Negotiation</h3>
    <span>{{ isOpen.salaryNegotiation ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.salaryNegotiation" class="dropdown-content">
    <p>Get tips on how to approach salary negotiations and ask for raises based on your role, experience, and market demand.</p>
  </div>

  <div @click="toggleSection('workLifeBalance')" :class="{'dropdown-header': true, 'is-open': isOpen.workLifeBalance}">
    <h3>Work-Life Balance</h3>
    <span>{{ isOpen.workLifeBalance ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.workLifeBalance" class="dropdown-content">
    <p>Explore strategies to maintain a healthy work-life balance and manage stress to avoid burnout.</p>
  </div>

  <div @click="toggleSection('leadershipManagement')" :class="{'dropdown-header': true, 'is-open': isOpen.leadershipManagement}">
    <h3>Leadership and Management</h3>
    <span>{{ isOpen.leadershipManagement ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.leadershipManagement" class="dropdown-content">
    <p>Learn how to develop leadership skills and improve your management style to take on leadership roles.</p>
  </div>

  <div @click="toggleSection('careerTransition')" :class="{'dropdown-header': true, 'is-open': isOpen.careerTransition}">
    <h3>Career Transition</h3>
    <span>{{ isOpen.careerTransition ? '▲' : '▼' }}</span>
  </div>
  <div v-if="isOpen.careerTransition" class="dropdown-content">
    <p>Get guidance on navigating career transitions, including changing industries or roles effectively.</p>
  </div>
</section>


  
      <!-- Resume and Career Goals Input -->
      <div v-if="!sessionStarted" class="form-group">
        <h2>Resume Text (Or background info)</h2>
        <textarea 
          v-model="resumeText" 
          placeholder="Paste your resume text / background information here"
          rows="5" 
          @input="checkResumeTextWordCount">
        </textarea>
        <p :class="{'word-limit-warning': resumeTextWordCount > 600, 'word-count': resumeTextWordCount <= 600}">
          {{ resumeTextWordCount }}/600
        </p>
        <p v-if="resumeTextWordCount > 600" class="word-limit-warning">
          The resume text exceeds 600 words. Please shorten it.
        </p>
  
        <h2>Career Goals</h2>
        <textarea 
          v-model="careerGoals" 
          placeholder="Describe your career goals and any specific areas you'd like advice on" 
          rows="5" 
          @input="checkCareerGoalsWordCount">
        </textarea>
        <p :class="{'word-limit-warning': careerGoalsWordCount > 600, 'word-count': careerGoalsWordCount <= 600}">
          {{ careerGoalsWordCount }}/600
        </p>
        <p v-if="careerGoalsWordCount > 600" class="word-limit-warning">
          The career goals text exceeds 600 words. Please shorten it.
        </p>
  
        <div class="button-container">
          <button @click="startCoaching" :disabled="!resumeText || !careerGoals || resumeTextWordCount > 600 || careerGoalsWordCount > 600 || isLoading">
            <span v-if="!isLoading">Start Coaching Session</span>
            <span v-else>Starting...</span>
          </button>
        </div>
      </div>
  
      <!-- Chat Interface -->
      <div v-if="sessionStarted" class="chat-interface">
        <div class="chat-log">
          <div v-for="(message, index) in chatHistory" :key="index" :class="['chat-bubble', message.role]">
            <div class="message-content">
              <strong>{{ message.role === 'user' ? 'You' : 'Career Coach' }}:</strong>
              <p v-html="formatMessage(message.content)"></p>
            </div>
          </div>
        </div>
  
        <div class="chat-input">
          <textarea v-model="userResponse" placeholder="Enter your response here" rows="3"></textarea>
          <button @click="sendResponse" :disabled="!userResponse || isLoading">
            <span v-if="!isLoading">Send Response</span>
            <span v-else>Sending...</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CareerCoach",
    data() {
      return {
        resumeText: "",
        careerGoals: "",
        userResponse: "",
        sessionId: null,
        chatHistory: [],
        sessionStarted: false,
        isLoading: false,
        resumeTextWordCount: 0,
        careerGoalsWordCount: 0,
        isOpen: {
          careerGoals: false,
          skillsAssessment: false,
          resumeFeedback: false,
          industryInsights: false,
          networkingAdvice: false,
        },
      };
    },
    methods: {
      toggleSection(section) {
        this.isOpen[section] = !this.isOpen[section];
      },
      checkResumeTextWordCount() {
        this.resumeTextWordCount = this.resumeText.split(/\s+/).filter(word => word.length > 0).length;
      },
      checkCareerGoalsWordCount() {
        this.careerGoalsWordCount = this.careerGoals.split(/\s+/).filter(word => word.length > 0).length;
      },
      async startCoaching() {
        if (this.resumeTextWordCount > 600 || this.careerGoalsWordCount > 600) {
          alert('Either the resume text or career goals exceeds the 600-word limit. Please shorten it.');
          return;
        }
  
        this.isLoading = true;
        try {
          const formData = new FormData();
          formData.append("resumeText", this.resumeText);
          formData.append("careerGoals", this.careerGoals);
  
          const response = await fetch("/career_coach/", {
  method: "POST",
  body: formData,
});

  
          const data = await response.json();
          if (data.error) {
            alert(data.error);
          } else {
            this.sessionId = data.sessionId;
            this.chatHistory.push({ role: "coach", content: data.assistantResponse });
            this.sessionStarted = true;
          }
        } catch (error) {
          console.error("Error starting coaching session:", error);
          alert("An error occurred while starting the coaching session.");
        } finally {
          this.isLoading = false;
        }
      },
      async sendResponse() {
        if (!this.userResponse) return;
        this.isLoading = true;
        try {
          const formData = new FormData();
          formData.append("resumeText", this.resumeText);
          formData.append("userResponse", this.userResponse);
          formData.append("sessionId", this.sessionId);
  
          const response = await fetch("http://35.184.17.9:5003/career_coach/", {
            method: "POST",
            body: formData,
          });
  
          const data = await response.json();
          if (data.error) {
            alert(data.error);
          } else {
            this.chatHistory.push({ role: "user", content: this.userResponse });
            this.chatHistory.push({ role: "coach", content: data.assistantResponse });
            this.userResponse = "";
          }
        } catch (error) {
          console.error("Error sending response:", error);
          alert("An error occurred while sending your response.");
        } finally {
          this.isLoading = false;
        }
      },
      formatMessage(message) {
      if (!message) return ''; // Add this line to handle undefined messages
      return message
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        .replace(/\*\*Feedback:\*\*/g, "<br><strong>Feedback:</strong>")
        .replace(/\*\*2\.\s/g, "<br><br><strong>Next Question:</strong><br>")
        .replace(/\n/g, "<br>");
    }
    }
  };
  </script>
  
  <style scoped>
  .career-coach {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'FixelDisplay', sans-serif;
  }
  
  .hero-background {
  background-color: #F3EDE2; /* The desired background color */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Stretch it across the full viewport width */
  z-index: -1; /* Place it behind the hero image */
  display: flex; /* Flexbox to align the height */
  justify-content: center;
  }
  
/* Hero image container */
.hero-image {
  width: 20vw; /* Adjust the width of the image */
  margin: 0 auto 20px auto; /* Center the image */
  position: relative;
  z-index: 1; /* Ensure it stays in front of the background */
}

/* Ensure the image scales properly inside the container */
.hero-image img {
  width: 100%; /* Make the image fit the width of the container */
  height: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the entire image is visible */
  border-radius: 10px;
}

/* Centered Hero Text Styling */
.hero-text {
  text-align: center;
  margin-bottom: 30px;
  font-family: 'FixelDisplay', sans-serif;
}
  
  .faq-section {
    width: 70%;
    margin: 0 auto;
    padding: 20px 0;
    background-color: #f3ede2;
  }
  
  .faq-section h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fbf7f0;
    padding: 15px 20px;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 1.2rem;
    box-sizing: border-box;
    margin-bottom: 10px;
    min-height: 60px;
  }
  
  .dropdown-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333333;
  }
  
  .dropdown-content {
    padding: 15px 20px;
    background-color: #fbf7f0;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-top: none;
    border-radius: 0 0 5px 5px;
    font-size: 1rem;
    line-height: 1.6;
    box-sizing: border-box;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  
  .dropdown-header.is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
  
  .form-group {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fbf7f0;
    box-sizing: border-box;
  }
  
  textarea {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 10px;
    resize: none;
    min-height: 100px;
    box-sizing: border-box;
    font-family: 'FixelDisplay', sans-serif;
  }
  
  button {
    background-color: #E07B3C;
    color: #fff;
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'FixelDisplay', sans-serif;
  }
  
  button:hover {
    background-color: #D2691E;
  }
  
  button:disabled {
    background-color: #999;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .chat-interface {
  max-width: 800px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
}

.chat-log {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
}

.chat-bubble {
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.user {
  background-color: #007aff;
  color: white;
  align-self: flex-end;
  text-align: right;
  margin-left: auto;
}

.coach {
  background-color: #e5e5ea;
  color: black;
  align-self: flex-start;
  text-align: left;
  margin-right: auto;
}

.chat-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.chat-input textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
  min-height: 80px;
}

.chat-input button {
  align-self: flex-end;
  width: auto;
}

  
  .word-count {
    font-size: 0.9rem;
    color: grey;
  }
  
  .word-limit-warning {
    color: red;
    font-size: 0.9rem;
  }
  
  /* ... (keep the previous styles) ... */

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .career-coach {
    padding: 10px;
  }

  .hero-image {
    width: 50vw;
  }

  .hero-text h1 {
    font-size: 1.8rem;
  }

  .hero-text p {
    font-size: 1rem;
  }

  .form-group {
    width: 90%;
    padding: 15px;
    margin: 0 auto 15px;
  }

  .faq-section {
    width: 90%;
  }

  .dropdown-header h3 {
    font-size: 1.1rem;
  }

  .dropdown-content {
    font-size: 1rem;
    line-height: 1.5;
  }

  .chat-interface {
    width: 100%;
    padding: 15px;
  }

  button {
    width: 100%;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .hero-image {
    width: 70vw;
  }

  .hero-text h1 {
    font-size: 1.5rem;
  }

  .hero-text p {
    font-size: 0.9rem;
  }

  .form-group,
  .faq-section {
    width: 95%;
  }

  .dropdown-header h3 {
    font-size: 1rem;
  }

  .dropdown-content {
    font-size: 0.9rem;
  }

  textarea {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
  </style>