<template>
  <div class="resume-best-practices">
    <!-- Hero Section -->
    <header class="hero">
      <div class="hero-background"></div> <!-- Background for the hero section -->
      <div class="hero-image-container">
        <img class="hero-image" src="@/assets/Resume_Best_Practices_Hero.png" alt="Resume Best Practices Hero" />
      </div>
      <div class="hero-content">
        <h1 class="hero-title">Resume Best Practices</h1>
        <p class="hero-subtitle">Top tips for structuring your resume to stand out to hiring managers.</p>
      </div>
    </header>

    <!-- Resume Best Practices Section with Dropdowns -->
    <section class="resume-tips-section">
      <!-- Dropdown for "Make your resume ATS-friendly" -->
      <div @click="toggleSection('atsFriendly')" :class="{'dropdown-header': true, 'is-open': isOpen.atsFriendly}">
        <h3>Make your resume ATS-friendly</h3>
        <span>{{ isOpen.atsFriendly ? '▲' : '▼' }}</span>
      </div>
      <div v-if="isOpen.atsFriendly" class="dropdown-content">
        <p>Optimize your resume for Applicant Tracking Systems (ATS)—software used by employers to screen resumes. Use simple formatting and job-relevant keywords to ensure your resume gets noticed.</p>
      </div>

      <!-- Dropdown for "Use the STAR method" -->
      <div @click="toggleSection('starMethod')" :class="{'dropdown-header': true, 'is-open': isOpen.starMethod}">
        <h3>Use the STAR method</h3>
        <span>{{ isOpen.starMethod ? '▲' : '▼' }}</span>
      </div>
      <div v-if="isOpen.starMethod" class="dropdown-content">
        <p>Use the STAR method to structure your accomplishments clearly and effectively:</p>
        <ul>
          <li><strong>Situation:</strong> Led a marketing team.</li>
          <li><strong>Task:</strong> Increased brand awareness.</li>
          <li><strong>Action:</strong> Launched a social media campaign.</li>
          <li><strong>Result:</strong> Boosted followers by 30% in 3 months.</li>
        </ul>
        <p>Here’s how it all comes together in one bullet point:</p>
        <p><strong>Led a marketing team to increase brand awareness by launching a social media campaign that boosted followers by 30% in 3 months.</strong></p>
      </div>

      <!-- Dropdown for "Submit in PDF format" -->
      <div @click="toggleSection('pdfFormat')" :class="{'dropdown-header': true, 'is-open': isOpen.pdfFormat}">
        <h3>Submit in PDF format</h3>
        <span>{{ isOpen.pdfFormat ? '▲' : '▼' }}</span>
      </div>
      <div v-if="isOpen.pdfFormat" class="dropdown-content">
        <p>Submitting your resume as a PDF ensures its format stays intact and works just as well as a .docx file for ATS.</p>
      </div>

      <!-- Dropdown for "Does a Resume need to be one page?" -->
      <div @click="toggleSection('onePage')" :class="{'dropdown-header': true, 'is-open': isOpen.onePage}">
        <h3>Does a Resume need to be one page?</h3>
        <span>{{ isOpen.onePage ? '▲' : '▼' }}</span>
      </div>
      <div v-if="isOpen.onePage" class="dropdown-content">
        <ul>
          <li>Your resume’s length depends on your experience. Keep it concise and relevant.</li>
          <li>Entry-level: aim for one page.</li>
          <li>Experienced professionals: two pages are fine if the content is impactful.</li>
        </ul>
      </div>

      <!-- Dropdown for "Is a cover letter necessary?" -->
      <div @click="toggleSection('coverLetter')" :class="{'dropdown-header': true, 'is-open': isOpen.coverLetter}">
        <h3>Is a cover letter necessary?</h3>
        <span>{{ isOpen.coverLetter ? '▲' : '▼' }}</span>
      </div>
      <div v-if="isOpen.coverLetter" class="dropdown-content">
        <ul>
          <li>A cover letter is important for highly competitive roles and elite companies but isn’t usually necessary for entry- and mid-level positions.</li>
          <li>Small businesses may value a cover letter more, while mid-to-large companies often focus solely on the resume.</li>
        </ul>
      </div>

      <!-- Dropdown for "Building a Resume with Limited Experience" -->
      <div @click="toggleSection('noExperience')" :class="{'dropdown-header': true, 'is-open': isOpen.noExperience}">
        <h3>Building a Resume with Limited Experience</h3>
        <span>{{ isOpen.noExperience ? '▲' : '▼' }}</span>
      </div>
      <div v-if="isOpen.noExperience" class="dropdown-content">
        <p>If you have limited formal experience, focus on a strong summary, highlight relevant projects, emphasize your skills, and showcase education or certifications.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ResumeBestPractices',
  data() {
    return {
      isOpen: {
        atsFriendly: false,
        starMethod: false,
        pdfFormat: false,
        noExperience: false,
        onePage: false,
        coverLetter: false,
      },
    };
  },
  methods: {
    toggleSection(section) {
      this.isOpen[section] = !this.isOpen[section];
    },
  },
};
</script>

<style scoped>

body {
  font-family: 'FixelDisplay', sans-serif;
  font-weight: 600;
  font-style: normal;

}
/* Hero Section */
.hero {
  position: relative;
  width: 100%;
  background-color: #f3ede2; /* Consistent background color */
  text-align: center;
  color: #333;
  padding: 40px 0;
  font-family: 'FixelDisplay', sans-serif;
}

.hero-background {
  background-color: #f3ede2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Hero Image Container */
.hero-image-container {
  width: 20vw;
  margin: 0 auto;
  z-index: 1;

}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;

}

/* Hero Content (text) positioning */
.hero-content {
  margin-top: 20px;

}

.hero-title {
  font-size: 2.5rem;
  color: #333;

}

.hero-subtitle {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: -10px;

}

/* Resume Tips Section */
.resume-tips-section {
  padding: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
  background-color: #f3ede2;
  font-family: 'FixelDisplay', sans-serif;

}

/* Dropdown Styles */
.dropdown-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fbf7f0;
  padding: 20px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 5px; /* Fully rounded */
  font-size: 1.6rem;
  box-sizing: border-box;
  margin-bottom: 20px; /* Add space between sections */

}

.dropdown-header h3 {
  margin: 0;
  font-size: 1.6rem;
  color: #333333;
}

.dropdown-content {
  padding: 20px 30px;
  background-color: #fbf7f0;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-top: none; /* Merge with header */
  border-radius: 0 0 5px 5px; /* Only bottom corners rounded */
  font-size: 1.4rem;
  line-height: 1.6;
  box-sizing: border-box;
  margin-bottom: 20px; /* Space after each section */
  margin-top: -20px; /* Collapse space between header and content */
}

/* When the dropdown is open, merge header and content */
.dropdown-header.is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0; /* Remove space between header and content */
}

.dropdown-content ul {
  list-style: disc inside;
  margin-left: 20px;
  font-size: 1.4rem;
}

/* Center the dropdown items */
.resume-tips-section .dropdown-header,
.resume-tips-section .dropdown-content {
  max-width: 85%;
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .hero {
    padding: 20px 0;
  }

  .hero-image-container {
    width: 50vw; /* Increase image size on mobile */
    
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .resume-tips-section {
    padding: 20px;
    width: 90%;
  }

  .dropdown-header {
    padding: 15px;
    font-size: 1.2rem;
  }

  .dropdown-header h3 {
    font-size: 1.2rem;
  }

  .dropdown-content {
    padding: 15px;
    font-size: 1rem;
  }

  .dropdown-content ul {
    font-size: 1rem;
    margin-left: 10px;
  }

  /* Center the dropdown items */
  .resume-tips-section .dropdown-header,
  .resume-tips-section .dropdown-content {
    max-width: 100%;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .hero-image-container {
    width: 70vw; /* Further increase image size on very small screens */
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .dropdown-header {
    padding: 12px;
    font-size: 1.1rem;
  }

  .dropdown-content {
    padding: 12px;
    font-size: 0.9rem;
  }
}
</style>
