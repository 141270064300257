<template>
  <div class="jobswipe">
    <header class="jobswipe__header">
      <div class="jobswipe__container">
        <!-- Updated Search Section -->
        <div class="search-section">
          <!-- Search Form -->
          <form @submit.prevent="executeSearch" class="search-form">
            <h1 class="search-title">Find Your Next Opportunity</h1>

            <!-- Job Title Search -->
            <div class="search-field">
              <label class="search-section-label">Job Title</label>
              <div class="search-input-wrapper">
                <input
                  v-model="searchQueries.title"
                  type="text"
                  placeholder="Search job titles, keywords, or companies"
                  class="search-input"
                />
                <span class="search-icon">
                  <SearchIcon />
                </span>
              </div>
            </div>

            <!-- Location Filter -->
            <div class="search-field">
              <label class="search-section-label">Location</label>
              <div class="search-input-wrapper">
                <LocationFilter
                  @update:location="handleLocationUpdate"
                  @error="handleLocationError"
                />
              </div>
            </div>

            <!-- Salary Range -->
            <div class="search-field">
              <label class="search-section-label">Salary Range</label>
              <div class="salary-range">
                <!-- Min Salary -->
                <div class="search-field">
                  <label class="search-label">Min Salary</label>
                  <div class="search-input-wrapper">
                    <select v-model="filters.salaryMin" class="search-select">
                      <option value="30000">$30,000</option>
                      <option value="40000">$40,000</option>
                      <option value="50000">$50,000</option>
                      <option value="60000">$60,000</option>
                      <option value="70000">$70,000</option>
                      <option value="80000">$80,000</option>
                      <option value="100000">$100,000</option>
                      <option value="120000">$120,000</option>
                      <option value="140000">$140,000</option>
                      <option value="175000">$175,000</option>
                      <option value="200000">$200,000</option>
                    </select>
                    <span class="search-icon">
                      <DollarSignIcon />
                    </span>
                  </div>
                </div>

                <!-- Max Salary -->
                <div class="search-field">
                  <label class="search-label">Max Salary</label>
                  <div class="search-input-wrapper">
                    <select v-model="filters.salaryMax" class="search-select">
                      <option value="30000">$30,000</option>
                      <option value="40000">$40,000</option>
                      <option value="50000">$50,000</option>
                      <option value="60000">$60,000</option>
                      <option value="70000">$70,000</option>
                      <option value="80000">$80,000</option>
                      <option value="100000">$100,000</option>
                      <option value="120000">$120,000</option>
                      <option value="140000">$140,000</option>
                      <option value="175000">$175,000</option>
                      <option value="200000">$200,000</option>
                      <option value="400000">$200,000+</option>
                    </select>
                    <span class="search-icon">
                      <DollarSignIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Job Type and Date Posted -->
            <div class="search-grid">
              <!-- Job Type -->
              <div class="search-field">
                <label class="search-section-label">Job Type</label>
                <div class="search-input-wrapper">
                  <select v-model="filters.jobType" class="search-select">
                    <option value="">All Types</option>
                    <option value="Full-Time">Full Time</option>
                    <option value="Part-Time">Part Time</option>
                    <option value="Contract">Contract</option>
                    <option value="Internship">Internship</option>
                  </select>
                  <span class="search-icon">
                    <BriefcaseIcon />
                  </span>
                </div>
              </div>

              <!-- Date Posted -->
              <div class="search-field">
                <label class="search-section-label">Date Posted</label>
                <div class="search-input-wrapper">
                  <select v-model="filters.datePosted" class="search-select">
                    <option value="1">Last 24 Hours</option>
                    <option value="3">Last 3 Days</option>
                    <option value="7">Last 7 Days</option>
                    <option value="14">Last 14 Days</option>
                    <option value="30">Last 30 Days</option>
                  </select>
                  <span class="search-icon">
                    <CalendarIcon />
                  </span>
                </div>
              </div>
            </div>

            <!-- Search Button -->
            <button 
              type="submit"
              class="search-button"
              :disabled="loading"
            >
              {{ loading ? 'Searching...' : 'Search Jobs' }}
            </button>
          </form>
        </div>
        <!-- End of Updated Search Section -->

        <!-- Error Modal -->
        <div v-if="error" class="error-modal">
          <div class="error-modal__content">
            <p>{{ error }}</p>
            <button @click="closeErrorModal" class="error-modal__button">Okay</button>
          </div>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="jobswipe__main">
      <!-- Loading State -->
      <div v-if="loading" class="jobs-loading">
        <div v-for="n in 3" :key="n" class="jobs-loading__card">
          <div class="jobs-loading__title"></div>
          <div class="jobs-loading__subtitle"></div>
          <div class="jobs-loading__text-short"></div>
          <div class="jobs-loading__text-long"></div>
        </div>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="jobs-error">
        {{ error }}
      </div>

      <!-- No Results -->
      <div v-else-if="displayedJobs.length === 0" class="jobs-empty">
        No jobs found. Try adjusting your search criteria.
      </div>

      <!-- Jobs Grid -->
      <div v-else class="jobs-grid">
        <div v-for="job in displayedJobs" :key="job.docId" class="job-card">

          <!-- Job Title & Company -->
          <div class="job-card__header">
            <div>
              <h2 class="job-card__title">{{ job.title }}</h2>
              <p class="job-card__company">{{ job.company }}</p>
            </div>
            <div class="job-card__actions">
              <button class="job-card__bookmark">
                <BookmarkIcon />
              </button>
            </div>
          </div>
          
          <!-- Location, Salary, Type -->
          <div class="job-card__details">
            <p><MapPinIcon class="icon-class" /> {{ job.location }}</p>
            <p><DollarSignIcon class="icon-class" /> ${{ job.salaryRange.min.toLocaleString() }} - ${{ job.salaryRange.max.toLocaleString() }}</p>
            <p><BriefcaseIcon class="icon-class" /> {{ job.type }}</p>
            <p v-if="job.remote || job.globallyRemote">
              <GlobeIcon class="icon-class" /> {{ job.globallyRemote ? 'Globally Remote' : 'Remote' }}
            </p>
          </div>

          <!-- Required Skills -->
          <div class="job-card__skills">
            <p class="job-card__skills-title">Required Skills:</p>
            <div class="job-card__skills-list">
              <span
                v-for="(skill, index) in job.requiredSkills"
                :key="index"
                class="job-card__skill-tag"
              >
                {{ skill.skill }}
              </span>
            </div>
          </div>

          <!-- Desired Skills -->
          <div v-if="job.desiredSkills && job.desiredSkills.length > 0" class="job-card__skills">
            <p class="job-card__skills-title">Desired Skills:</p>
            <div class="job-card__skills-list">
              <span
                v-for="(skill, index) in job.desiredSkills"
                :key="`desired-${index}`"
                class="job-card__skill-tag"
              >
                {{ skill.skill }}
              </span>
            </div>
          </div>

          <!-- View Details Button -->
          <button 
            @click="viewJobDetails(job)"
            class="job-card__cta"
          >
            View Details
          </button>
        </div>
      </div>

      <!-- Load More -->
      <div v-if="hasMoreJobs && displayedJobs.length > 0" class="load-more">
        <button
          @click="loadMore"
          class="load-more__button"
          :disabled="loading"
        >
          {{ loading ? 'Loading...' : 'Load More Jobs' }}
        </button>
      </div>

      <!-- Job Details Modal -->
      <div 
        v-if="selectedJob" 
        class="job-modal-overlay"
        @click="closeJobDetails"
      >
        <div 
          class="job-modal__content"
          @click.stop
        >
          <div class="job-modal__header">
            <div>
              <h2 class="job-modal__title">{{ selectedJob.title }}</h2>
              <p class="job-modal__company">{{ selectedJob.company }}</p>
            </div>
            <button 
              @click="closeJobDetails"
              class="job-modal__close"
            >
              <XIcon />
            </button>
          </div>

          <div class="job-modal__details">
            <div class="job-modal__info">
              <p><MapPinIcon class="icon-class" /> {{ selectedJob.location }}</p>
              <p><DollarSignIcon class="icon-class" /> ${{ selectedJob.salaryRange.min.toLocaleString() }} - ${{ selectedJob.salaryRange.max.toLocaleString() }}</p>
              <p><BriefcaseIcon class="icon-class" /> {{ selectedJob.type }}</p>
              <p v-if="selectedJob.remote || selectedJob.globallyRemote">
                <GlobeIcon class="icon-class" /> {{ selectedJob.globallyRemote ? 'Globally Remote' : 'Remote' }}
              </p>
              <p><CalendarIcon class="icon-class" /> Posted {{ formatDate(selectedJob.datePosted) }}</p>
            </div>

            <div class="job-modal__section">
              <h3 class="job-modal__subtitle">Description</h3>
              <div class="job-modal__description" v-html="formatDescription(selectedJob.description)"></div>
            </div>

            <div class="job-modal__section">
              <h3 class="job-modal__subtitle">Required Skills</h3>
              <div class="job-modal__skills">
                <span
                  v-for="(skill, index) in selectedJob.requiredSkills"
                  :key="index"
                  class="job-modal__skill-tag"
                >
                  {{ skill.skill }}
                </span>
              </div>
            </div>

            <div v-if="selectedJob.desiredSkills?.length" class="job-modal__section">
              <h3 class="job-modal__subtitle">Desired Skills</h3>
              <div class="job-modal__skills">
                <span
                  v-for="(skill, index) in selectedJob.desiredSkills"
                  :key="`desired-${index}`"
                  class="job-modal__skill-tag"
                >
                  {{ skill.skill }}
                </span>
              </div>
            </div>

            <!-- Modal Actions -->
            <div class="job-modal__actions">
              <button 
  class="job-modal__apply"
  @click="applyToJob(selectedJob.docId)"
>
  Apply Now
</button>

              <button 
                class="job-modal__share"
                @click="shareJob(selectedJob)"
              >
                <ShareIcon />
                Share
              </button>
              <button 
                class="job-modal__close-button"
                @click="closeJobDetails"
              >
                Close
              </button>
            </div>
            <!-- End of Modal Actions -->
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { 
  collection, 
  query, 
  where, 
  getDocs,
} from "firebase/firestore";
import LocationFilter from '@/components/LocationFilter.vue';
import { marked } from 'marked';
import { 
  SearchIcon, 
  MapPinIcon, 
  BriefcaseIcon,
  DollarSignIcon,
  CalendarIcon,
  XIcon,
  BookmarkIcon,
  GlobeIcon,
  ShareIcon
} from 'lucide-vue-next';

export default {
  components: {
    LocationFilter,
    SearchIcon,
    MapPinIcon,
    BriefcaseIcon,
    DollarSignIcon,
    CalendarIcon,
    XIcon,
    BookmarkIcon,
    GlobeIcon,
    ShareIcon
  },

  data() {
    return {
      searchQueries: {
        title: "",
        skills: ""
      },
      filters: {
        jobType: "",
        salaryMin: "50000",
        salaryMax: "80000",
        datePosted: "30",
      },
      locationFilter: {
        city: "",
        state: "",
        coordinates: null,
        radius: 30,
        searchRange: null,
        isRemoteOnly: false
      },
      jobs: [],
      displayedJobs: [],
      loading: false,
      error: null,
      pageSize: 20,
      selectedJob: null
    };
  },

  computed: {
    hasActiveFilters() {
      return this.locationFilter.city || 
             this.filters.jobType || 
             this.filters.salaryMin || 
             this.filters.salaryMax ||
             this.filters.datePosted;
    },
    isLocationValid() {
      return this.locationFilter.city && this.locationFilter.state;
    },
    hasMoreJobs() {
      return this.displayedJobs.length < this.jobs.length;
    }
  },

  methods: {
    closeErrorModal() {
      this.error = null;
    },

    handleLocationUpdate(locationData) {
      this.locationFilter = locationData;
    },

    handleLocationError(error) {
      this.error = error;
    },

    viewJobDetails(job) {
      this.selectedJob = job;
    },

    closeJobDetails() {
      this.selectedJob = null;
    },

    applyToJob(docId) {
  if (docId) {
    const url = this.$router.resolve({ name: 'JobApply', params: { docId: docId } }).href;
    window.open(url, '_blank');
  } else {
    console.error("Job document ID is not available.");
  }
}


,


shareJob(job) {
    const jobUrl = `${window.location.origin}/apply/${job.docId}`;
    
    // Copy the job URL to the clipboard
    navigator.clipboard.writeText(jobUrl)
      .then(() => {
        // Show the success message
        this.showCopyPopup = true;

        // Hide the pop-up after 2 seconds
        setTimeout(() => {
          this.showCopyPopup = false;
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy job URL: ", error);
      });
  },

    formatDescription(description) {
      if (!description) return '';
      
      const formattedText = description
        .replace(/\\n\\n/g, '\n\n')
        .replace(/\\n/g, '\n')
        .replace(/\*\*/g, '**');

      marked.setOptions({
        breaks: true,
        gfm: true
      });
      
      return marked(formattedText);
    },

    async executeSearch() {
      if (!this.isLocationValid && !this.locationFilter.isRemoteOnly) {
        this.error = "Please select both a city and a state to search.";
        return;
      }

      this.error = null;
      this.loading = true;
      this.jobs = [];

      try {
        let newJobs = [];
        const globalQuery = await this.buildGlobalRemoteQuery();
        const globalSnapshot = await getDocs(globalQuery);
        newJobs = globalSnapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }));

        if (this.locationFilter.state) {
          const stateQuery = await this.buildStateRemoteQuery();
          const stateSnapshot = await getDocs(stateQuery);
          newJobs = [...newJobs, 
            ...stateSnapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }))
          ];
        }

        if (!this.locationFilter.isRemoteOnly && this.locationFilter.coordinates) {
          const localQuery = await this.buildNonRemoteQuery();
          const localSnapshot = await getDocs(localQuery);
          newJobs = [...newJobs,
            ...localSnapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }))
          ];
        }

        const filteredJobs = this.clientSideFilter(newJobs);
        const uniqueJobs = [...new Map(filteredJobs.map(job => [job.docId, job])).values()];


        // Assign match scores and sort the jobs
        uniqueJobs.forEach(job => {
          job.matchScore = this.computeMatchScore(job.title, this.searchQueries.title);
        });

        uniqueJobs.sort((a, b) => {
          if (b.matchScore !== a.matchScore) {
            return b.matchScore - a.matchScore; // Sort by match score descending
          } else {
            return b.datePosted.toDate() - a.datePosted.toDate(); // Then by date posted descending
          }
        });

        this.jobs = uniqueJobs;
        this.displayedJobs = this.jobs.slice(0, this.pageSize);

      } catch (error) {
        console.error("Error fetching jobs:", error);
        this.error = "Error fetching jobs: " + error.message;
      } finally {
        this.loading = false;
      }
    },

    computeMatchScore(jobTitle, searchTitle) {
      if (!searchTitle) {
        return 0;
      }
      const jobTitleLower = jobTitle.toLowerCase();
      const searchTitleLower = searchTitle.toLowerCase();

      if (jobTitleLower === searchTitleLower) {
        return 3;
      } else if (jobTitleLower.includes(searchTitleLower)) {
        return 2;
      } else {
        const titleTerms = searchTitleLower.split(' ').filter(term => term.length > 0);
        if (titleTerms.every(term => jobTitleLower.includes(term))) {
          return 1;
        } else {
          return 0;
        }
      }
    },

    async buildGlobalRemoteQuery() {
      const constraints = [];
      const jobsCollection = collection(db, "jobs");

      constraints.push(where("isActive", "==", true));
      constraints.push(where("globallyRemote", "==", true));

      if (this.filters.jobType) {
        constraints.push(where("type", "==", this.filters.jobType));
      }

      return query(jobsCollection, ...constraints);
    },

    async buildStateRemoteQuery() {
      const constraints = [];
      const jobsCollection = collection(db, "jobs");

      constraints.push(where("isActive", "==", true));
      constraints.push(where(`remoteStatesMap.${this.locationFilter.state}`, "==", true));

      if (this.filters.jobType) {
        constraints.push(where("type", "==", this.filters.jobType));
      }

      return query(jobsCollection, ...constraints);
    },

    async buildNonRemoteQuery() {
      const constraints = [];
      const jobsCollection = collection(db, "jobs");

      constraints.push(where("isActive", "==", true));
      constraints.push(where("remote", "==", false));

      if (this.locationFilter.coordinates && this.locationFilter.searchRange) {
        constraints.push(where("coordinates.longitude", ">=", this.locationFilter.searchRange.lonMin));
        constraints.push(where("coordinates.longitude", "<=", this.locationFilter.searchRange.lonMax));
      }

      if (this.filters.jobType) {
        constraints.push(where("type", "==", this.filters.jobType));
      }

      return query(jobsCollection, ...constraints);
    },

    clientSideFilter(jobs) {
      const daysAgo = parseInt(this.filters.datePosted);
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - daysAgo);

      return jobs.filter(job => {
        // Compute match score for each job
        job.matchScore = this.computeMatchScore(job.title, this.searchQueries.title);

        // Include all jobs; match score will be used for sorting
        const matchesSalary = (!this.filters.salaryMin || job.salaryRange.max >= this.filters.salaryMin) &&
                               (!this.filters.salaryMax || job.salaryRange.min <= this.filters.salaryMax);

        const matchesDatePosted = !job.datePosted || job.datePosted.toDate() >= cutoffDate;

        let matchesLocation = true;
        if (this.locationFilter.coordinates && 
            !this.locationFilter.isRemoteOnly && 
            !job.remote && 
            !job.globallyRemote) {
          const jobLat = job.coordinates?.latitude;
          const jobLon = job.coordinates?.longitude;
          matchesLocation = jobLat >= this.locationFilter.searchRange.latMin &&
                            jobLat <= this.locationFilter.searchRange.latMax &&
                            jobLon >= this.locationFilter.searchRange.lonMin &&
                            jobLon <= this.locationFilter.searchRange.lonMax;
        }

        return matchesSalary && matchesDatePosted && matchesLocation;
      });
    },

    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      const now = new Date();
      const diffTime = Math.abs(now - date);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 0) return 'Today';
      if (diffDays === 1) return 'Yesterday';
      if (diffDays < 7) return `${diffDays} days ago`;
      
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },

    loadMore() {
      const currentLength = this.displayedJobs.length;
      const newJobs = this.jobs.slice(currentLength, currentLength + this.pageSize);
      this.displayedJobs = [...this.displayedJobs, ...newJobs];
    }
  }
};
</script>

<style>
/* Base Layout */
* {
  box-sizing: border-box;
}

body, .jobswipe {
  font-family: 'FixelDisplay', sans-serif;
}

.jobswipe {
  min-height: 100vh;
  background-color: #f3ede2;
  max-width: 70%;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0 auto;
}

.jobswipe__header {
  background-color: white;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border-radius: 5px; /* Fully rounded */
}

.jobswipe__container {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1rem;
  width: 95%;
  
}

.jobswipe__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(17, 24, 39);
  margin-bottom: 1.5rem;
}

.jobswipe__main {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1.5rem 1rem;
}

/* Hide the unwanted element from LocationFilter */
.search-section .text-sm.text-gray-600 {
  display: none;
}

/* Search Section Styling */
.search-section {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  
}

.search-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fc7115;
  margin-bottom: 2rem;
}

.search-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

/* Updated label styles */
.search-section-label {
  font-size: 1.125rem;
  font-weight: 700;
  color: #333;
  margin-top: 1rem;
}

.search-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #666;
}

.search-input-wrapper {
  position: relative;
}



.search-input,
.search-select {
  width: 100%;
  padding: 0.875rem 1rem;
  padding-left: 2.5rem;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  background-color: white;
  outline: none;
  font-size: 1rem;
}

.search-input:focus,
.search-select:focus {
  border-color: rgb(59, 130, 246);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(156, 163, 175);
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.search-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 640px) {
  .search-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.salary-range {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 640px) {
  .salary-range {
    grid-template-columns: repeat(2, 1fr);
  }
}

.search-button {
  padding: 0.75rem 1.5rem;
  background-color: #fc7115;
  color: white;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  width: fit-content;
  align-self: flex-start;
}

.search-button:hover:not(:disabled) {
  background-color: #e35d0a;
}

/* Jobs Grid */
.jobs-grid {
  display: grid;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .jobs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .jobs-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Job Card */
.job-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition: box-shadow 0.2s;
}

.job-card:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.job-card__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.job-card__title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fc7115;
}

.job-card__title:hover {
  text-decoration: underline;
}

.job-card__company {
  font-weight: 500;
  color: #333;
  margin-top: 0.25rem;
}

.job-card__details {
  color: #333;
  margin-bottom: 1.5rem;
}

.job-card__details > p {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.job-card__details > p > span {
  margin-right: 0.5rem;
}

.job-card__skills-title {
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.job-card__skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.job-card__skill-tag {
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.job-card__cta {
  width: 100%;
  background-color: #fc7115;
  color: white;
  font-weight: 500;
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
}

.job-card__cta:hover {
  background-color: #e35d0a;
}

/* Job Modal Overlay */
.job-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Job Modal Content */
.job-modal__content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.job-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-modal__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fc7115;
}

.job-modal__company {
  font-weight: 500;
  color: #333;
  margin-top: 0.25rem;
}

.job-modal__close {
  background: none;
  border: none;
  cursor: pointer;
}

.job-modal__details {
  margin-top: 1.5rem;
}

.job-modal__info > p {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.job-modal__info > p > span {
  margin-right: 0.5rem;
}

.job-modal__section {
  margin-top: 1.5rem;
}

.job-modal__subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.job-modal__description {
  color: #555;
}

.job-modal__skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.job-modal__skill-tag {
  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

/* Modal Actions */
.job-modal__actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.job-modal__apply,
.job-modal__share,
.job-modal__close-button {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  border-radius: 0.5rem;
  cursor: pointer;
}

.job-modal__apply {
  background-color: #fc7115;
  color: white;
  border: none;
}

.job-modal__apply:hover {
  background-color: #e35d0a;
}

.job-modal__share {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.job-modal__share:hover {
  background-color: #2563eb;
}

.job-modal__close-button {
  background-color: #6b7280;
  color: white;
  border: none;
}

.job-modal__close-button:hover {
  background-color: #4b5563;
}

/* Icon Styling */
.icon-class {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.5rem;
}
</style>
