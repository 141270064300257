<template>
  <div class="home">
    <!-- Hero Section -->
    <header class="hero">
      <div class="hero-content">
        <img src="@/assets/logo.png" alt="Fylter Logo" class="logo" />
        <h1 class="hero-title">Your Career Starts Here</h1>
        <p class="hero-subtitle">Find verified jobs with Fylter's AI-powered job board.</p>
        <button class="cta-btn" @click="openSignUpModal">Sign Up For Early Access!</button>
      </div>
    </header>

    <!-- Mockup Section for showcasing the phone mockups -->
    <section class="mockup-section">
      <MockupSection /> 
    </section>

    <!-- New Sign-Up Section -->
    <section id="sign-up" class="sign-up-section">
      <div class="sign-up-content">
        <h1>Be the First to Access Fylter Jobs</h1>
        <p>Join our early access list and stay updated on our launch.</p>
        <SignUpForm />
      </div>
    </section>

    <!-- Features Section -->
    <section class="features">
      <div class="feature">
        <i class="fas fa-mouse-pointer fa-3x feature-icon"></i> <!-- Icon for One-Click Apply -->
        <h2>One-Click Apply</h2>
        <p>Streamline your job applications based on your profile, saving time by avoiding repetitive form filling.</p>
      </div>
      <div class="feature">
        <i class="fas fa-cogs fa-3x feature-icon"></i> <!-- Icon for Jobs Tailored to Your Skills -->
        <h2>Jobs Tailored to Your Skills</h2>
        <p>Our AI suggests jobs that match your top skills, helping you find the most relevant opportunities.</p>
      </div>
      <div class="feature">
        <i class="fas fa-filter fa-3x feature-icon"></i> <!-- Icon for Fylter Jobs -->
        <h2>Fylter Jobs</h2>
        <p>Use advanced filters to refine your search and discover jobs that perfectly fit your criteria.</p>
      </div>
    </section>

    <!-- App Download Section for iOS and Android -->
    <AppDownload />
  </div>
</template>

<script>
import MockupSection from '@/components/MockupSection.vue';
import AppDownload from '@/components/AppDownload.vue';
import SignUpForm from '@/components/SignUpForm.vue';

export default {
  name: 'HomePage',
  components: {
    MockupSection,
    AppDownload,
    SignUpForm,
  },
  methods: {
    openSignUpModal() {
  const signUpSection = document.getElementById('sign-up');
  if (signUpSection) {
    signUpSection.scrollIntoView({ behavior: 'smooth' });
  }
}
,
scrollToSignUpSection() {
    const signUpSection = document.getElementById('sign-up'); // Ensure this ID matches the one in your sign-up section
    if (signUpSection) {
      this.smoothScrollTo(signUpSection, 1500);
    }
  },
  smoothScrollTo(target, duration) {
    const startY = window.pageYOffset;
    const targetY = target.getBoundingClientRect().top + startY;
    const distanceY = targetY - startY;
    let startTime = null;

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startY, distanceY, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    requestAnimationFrame(animation);
    },
  },
  mounted() {
    // Intersection Observer for scroll animations
    const options = {
      root: null,
      threshold: 0.1, // Trigger when 10% of the element is visible
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view'); // Add 'in-view' class when the section is visible
          observer.unobserve(entry.target); // Stop observing after it's in view
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    // Observe the sign-up section
    const signUpSection = document.getElementById('sign-up');
    if (signUpSection) {
      observer.observe(signUpSection);
    }
  },
};
</script>


<style scoped>
/* General Page Styles */


.home {
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: #f3ede2;
  width: 100%; /* Ensure the home section spans full width of the viewport */
  font-family: 'FixelDisplay', sans-serif;

}

/* Ensure full width for each section */
section {
  width: 100%; /* Full width of the viewport */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Hero Section */
.hero {
  background-color: #f3ede2;
  text-align: center;
  color: #333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure full width */
  margin: 0;
  background-size: cover;
}

.hero-content {
  max-width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.hero-content .logo {
  width: 225px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.2s;
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
  color: #fc7115;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.4s;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #333;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.6s;
}

.cta-btn {
  background-color: #fc7115;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards, pulse 2s infinite;
  animation-delay: 0.8s, 0s;
  font-family: 'FixelDisplay', sans-serif;
}

.cta-btn:hover {
  background-color: #e35d0a;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Pulsing effect for CTA button */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(252, 113, 21, 0.7);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(252, 113, 21, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(252, 113, 21, 0);
  }
}

/* Mockup Section */
.mockup-section {
  margin: 40px 0;
  width: 100%; /* Ensure full width */
}

/* Features Section */
.features {
  display: flex;
  flex-wrap: wrap; /* Allow features to wrap on smaller screens */
  justify-content: center;
  padding: 40px;
  background-color: #fbf7f0;
  width: 100%;
  box-sizing: border-box; /* Include padding in width calculation */
}

.feature {
  text-align: center;
  max-width: 300px;
}

.feature-icon {
  margin-bottom: 20px;
}

.feature h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.feature p {
  font-size: 1rem;
  color: #666;
}

/* Sign-Up Section */
.sign-up-section {
  padding: 80px 20px;
  background-color: #f3ede2;
  text-align: center;
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Initially moved down */
  transition: opacity 1s ease-out, transform 1s ease-out; /* Smooth transition */
  width: 100%; /* Ensure full width */
}

.sign-up-section.in-view {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Move to original position */
}

.sign-up-content {
  max-width: 600px;
  margin: 0 auto;
}

.sign-up-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #fc7115;
}

.sign-up-section p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #666;
}

/* Footer */
.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
  width: 100%; /* Ensure full width */
}

* {
  max-width: 100%;
  box-sizing: border-box;
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .hero-content,
  .features,
  .sign-up-section,
  .mockup-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .cta-btn {
    padding: 12px 24px;
    font-size: 1rem;
  }

  .features {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    margin-bottom: 30px;
  }

  .sign-up-section h1 {
    font-size: 2rem;
  }

  .sign-up-section p {
    font-size: 1rem;
  }
}

/* Ensure responsiveness for very small screens */
@media screen and (max-width: 480px) {
  .hero-content,
  .features,
  .sign-up-section,
  .mockup-section {
    padding-left: 15px;
    padding-right: 15px;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content .logo {
    width: 180px;
  }
}
</style>
